.ripple {
  position: relative;
  // overflow: hidden;
  // transform: translate3d(0, 0, 0);
}
  
.ripple--container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  span { /* ripple element is dark */
    transform: scale(0);
    border-radius: 100%;
    position: absolute;
    opacity: 0.75;
    background-color: rgba(255, 255, 255, .5);
    
    -webkit-animation: ripple 1000ms; /* Safari 4+ */
    -moz-animation: ripple 1000ms; /* Fx 5+ */
    -o-animation: ripple 1000ms; /* Opera 12+ */
    animation: ripple 1000ms; /* IE 10+, Fx 29+ */
  }
  &.--dark-effect span { /* ripple element is light */
    background-color: rgba(0, 0, 0, .5);
  }
}

@-moz-keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@-webkit-keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@-o-keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}