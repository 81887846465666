.header-wrapper {
  background-color: $header-bg-color;
  background-image: $header-bg-image;
  background-position: right;
  height: $header-height;
  
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: $header-shadow;
  
  .company {
    height: 100%;
    min-width: $navbar-width;
    color: $navbar-color;

    display: flex;
    align-items: center;

    .menu-icon {
      margin-left: 22px;
      cursor: pointer;
      &::before {
        transition: .25s all ease-in-out;
      }
    }

    .company-name {
      margin: 0;
      cursor: pointer;
      margin-left: 16px;
      font-size: 20px;
    }
  }

  .header-details {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-self: stretch;
    align-items: center;
  }

  .search-box {
    height: 28px;
    margin-right: 16px;
    margin-top: 0;
  }

  .header-options {
    display: flex;
    align-self: stretch;
    align-items: center;
    padding-right: $page-spacing;

    .user-avatar {
      margin: auto 4px;
      height: 30px;
      width: 30px;
      text-align: center;
      align-self: stretch;
      position: relative;
    }
    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      &:hover { opacity: .9; }
    }
    

    .btn-header-icon {
      height: 30px;
      width: 30px;
      line-height: 1;

      margin: auto 4px;
      align-self: stretch;
      font-size: 16px;
      border: none;
      border-radius: 50%;

      color: $light-gray;
      background-color: transparent;
      position: relative;
      cursor: pointer;
      
      .icon, .fa {
        color: inherit;
      }

    }

    .has-notification { // button has notification
      // background: rgba($secondary-color, .45)!important;
      background: rgba($secondary-color, .45);
      .notification-count {
        position: absolute;
        // top: 1px;
        // right: 1px;
        top: 3px;
        right: 2px;
        background-color: $secondary-color;
        color: #FFFFFF;
        font-size: 10px;
        line-height: 1;
        padding: 2px;
        border-radius: 50%;
        min-width: 12px;
      }
    }
    .active { // button and user image (div)
      color: $secondary-color;
      &::after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: calc(100% + ((#{$header-height} - 30px) / 2));
        left: 50%;
        transform: translateX(-50%);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 5px solid $header-button-active;
        z-index: 11;
      }
    }

    .active-message {
      background: initial !important;
      color: $secondary-color;
      .notification-count {
        visibility: hidden;
      }
    }
  }

  
}