@import '~assets/scss/variables';
.btn-icon-small {
    height: 25px !important;
}

.company-logo {
    min-height: 60px;
    height: auto !important;
    overflow: hidden !important;
    .cl-mini {
        flex-basis: 30px;
        height: 30px;
        width: 100%;
    }
    .cl-normal {
        flex-basis: 40px;
        height: 40px;
        width: 100%;
    }
    .levels-block {
        flex: 1;
    }
    .levels-title {
        color: #fff;
        margin: 0;
        font-size: .7em;
        font-weight: bold;
        text-align: center;
        opacity: .5;
    }
}

nav.nav {
    height: calc(100% - 292px) !important;
}

.mapPOIlegend {
    background-color: white;
    width: 3.2em;
    padding: 1em .5em;
    max-height: calc(100% - 5em);
    min-height: 5em;
    position: absolute;
    left: 0;
    top: 50% !important;
    transform: translateY(-50%) !important;
    overflow-y: scroll;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    box-shadow: $shadowBottom; // margin-top: .5em;
    -webkit-transition: width .3s;
    transition: width .3s;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
        display: none;
    }
    &:hover {
        width: 20em;
        div.label {
            display: inline-block !important;
        }
    }
    &.noitems {
        display: none;
    }
    div {
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        img {
            display: inline-block;
            width: 2em;
        }
        div.label {
            padding-left: 1em;
            height: 100%;
            line-height: 100%;
            font-size: 1.2em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $text-color;
            font-weight: bold;
            display: none;
        }
    }
}

.mapInfoLabel {
    font-weight: bold;
    font-size: 1.5em;
    background-color: white;
    padding: .3em 1em;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    box-shadow: $shadowBottom;
}

.property-image {
    position: relative;
    .riskValue {
        position: absolute;
        bottom: .3em;
        left: .3em;
        padding: .1em .2em;
        border-radius: .2em;
        color: white;
        font-size: .8em;
        background-color: #E5E5E5;
    }
}

.rating-stars-custom {
    .star {
        font-size: 2em; // font-size: x-large;
        width: 40px;
        display: inline-block;
        color: gray; // border: 1px solid red;
        position: relative;
        &:last-child {
            margin-right: 0;
        }
        &:before {
            content: '\2606';
        }
        &.on {
            color: $main-color;
            &:before {
                content: '\2605';
            }
        }
        &.half:after {
            content: '\2605';
            color: $main-color;
            position: absolute;
            margin-left: -32px;
            width: 16px;
            overflow: hidden;
        }
    }
}

form.ng-submited {
    .ui-float-label input.ng-invalid~label {
        color: $error-color;
    }
    .ui-inputtext.ng-invalid,
    p-dropdown.ng-invalid>.ui-dropdown,
    p-autocomplete.ng-invalid>.ui-autocomplete>.ui-inputtext,
    p-calendar.ng-invalid>.ui-calendar>.ui-inputtext,
    p-chips.ng-invalid>.ui-inputtext,
    p-inputmask.ng-invalid>.ui-inputtext,
    p-checkbox.ng-invalid .ui-chkbox-box,
    p-radiobutton.ng-invalid .ui-radiobutton-box,
    p-inputswitch.ng-invalid .ui-inputswitch,
    p-listbox.ng-invalid .ui-inputtext,
    p-multiselect.ng-invalid>.ui-multiselect,
    p-spinner.ng-invalid>.ui-inputtext,
    p-selectbutton.ng-invalid .ui-button,
    p-togglebutton.ng-invalid .ui-button {
        border-color: $error-color;
    }
    .ui-inputtext.ng-invalid:hover,
    p-dropdown.ng-invalid>.ui-dropdown:hover,
    p-autocomplete.ng-invalid>.ui-autocomplete>.ui-inputtext:hover,
    p-calendar.ng-invalid>.ui-calendar>.ui-inputtext:hover,
    p-chips.ng-invalid>.ui-inputtext:hover,
    p-inputmask.ng-invalid>.ui-inputtext:hover,
    p-checkbox.ng-invalid .ui-chkbox-box:hover,
    p-radiobutton.ng-invalid .ui-radiobutton-box:hover,
    p-inputswitch.ng-invalid .ui-inputswitch:hover,
    p-listbox.ng-invalid .ui-inputtext:hover,
    p-multiselect.ng-invalid>.ui-multiselect:hover,
    p-spinner.ng-invalid>.ui-inputtext:hover,
    p-selectbutton.ng-invalid .ui-button:hover,
    p-togglebutton.ng-invalid .ui-button:hover {
        border-color: $error-color;
    }
}
