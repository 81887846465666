/*
 =========================================================
 * CUSTOM LAYOUT DESIGN
 *
 * Layout, content, progress-bars, file-upload & preview-box,
 * Fixer classes, helper classes, global responsiveness...
 *
 * IMPORTANT: add styles after imports
 =========================================================
 */

// COVER FOR LOADERS, LOGIN, REGISTER PAGES
.main-loader, app-login, app-register {
    display: block;
    height: 100vh;
    background-color: $content-bg-color;
    background-image: url('./assets/img/pexels-photo-590022.png');
    background-repeat: no-repeat;
    background-size: cover;
}

body {
  color: $main-text-color;
}
// SCROLLBARS
::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: $main-scrollbar-bg-color;
    outline: 1px solid $main-scrollbar-bg-color; // border-radius: 2px;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

.__hide-pre-code {
  pre {
    display: none !important;
  }
}
.__switching-theme {
  * {
    transition: 300ms all linear;
  }
}

// ::selection {
//   background: rgba(144, 144, 144, 0.5);
// }

.chart-container {
  position: relative;
  width: auto;
  height: 100%;

  &.compact {
    height: 300px;
    width: 100%;
  }

  .chartjs-render-monitor {
    max-height: 100%;
    // height: auto !important;
    width: inherit !important;
    margin: 0 auto;
  }
}

.multiple-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .content:first-of-type {
    padding-right: 7.5px;
  }
  .content:not(:first-of-type) {
    padding-left: 7.5px;
  }
}

// MAIN PAGE CONTENT
.content {
    height: 100%;
    margin: 0!important;
    padding: $page-spacing;
    position: relative;
    overflow-y: auto;
}

.content-inner {
    height: auto;
    min-height: 100%;
    padding: $page-spacing;
    background-color: $content-bg-color;
    background: $content-bg;
    color: $content-color;
    border-top: 2px solid $secondary-color;
    border-radius: 2px;
    // box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.3);
}
.hide-scroll {
  height: 100%;
  overflow-y: hidden;
}
.hide-scroll--has-footer {
  height: calc(100% - 40px);
  min-height: auto;
  overflow-y: hidden;
}
.inner-scroll {
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background: $main-color;
    border-radius: 2px;
  }
}


.content-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  // height: 50px;
  // border-bottom: 2px solid $light-gray;
  height: $content-header-height;
  min-height: $content-header-height;
  background-color: $content-header-bg-color;
  padding: 0 5px;

  .content-title {
      color: $text-color; //$dark-gray;
      // font-weight: 600;
      // margin-top: 6px;
      // margin: 0;
      font-size: 1.7rem;
      font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
      font-weight: 400;
      text-transform: capitalize;
      align-self: center;
      margin-bottom: 0;
      padding: 5px 10px;
      label {
        margin-bottom: 0;
      }
  }
  .content-options {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      // margin-right: -18px;

      >:first-child {
        margin-left: 0px;
      }
      > * {
        margin-left: 8px;
        height: 34px;
      }

      > label {
        line-height: 34px;
        font-size: 14px;
        margin-bottom: 0;
        color: $dark-gray;
      }
      .ui-control {
        margin-top: 0;
        height: 34px;
      }
      .btn {
          font-size: 14px;
          line-height: 34px;
          min-width: 30px !important;
          .icon,
          .fa {
              color: $text-color;
          }
          .icon.tt-more {
              // color: rgba($dark-gray, 0.75);
              color: rgba($text-color, 0.85);
          }
          // &:hover:not(:disabled) {
          //     background-color: #f4f4f4 !important;
          // }
      }
  }
}


.app-footer-wrapper {
  height: $footer-height;
  background-color: $footer-bg-color;
  color: $footer-color;
  position: fixed;
  left: $navbar-width;
  right: 0;
  bottom: 0;
  transition: $layoutTransition;
  box-shadow: $shadowTop;
  padding: 0 20px;
  display: flex;
  align-items: center;
}








/*
 =========================================================
 * CUSTOM COMPONENTS
 =========================================================
 */


// chart.js widgets
.widget {
    box-shadow: $shadowBottom;
}

quill-editor {
    display: block;
    width: 100%;
    .ql-editor {
        font-size: 1.6rem; // line-height: 1.5;
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: rgba($main-color, .5);
      background-color: $form-control-bg-color;
    }
    .ql-editor.ql-blank::before {
      color: rgba($main-text-color, 0.6);
    }

    .ql-snow .ql-fill {
      fill: $main-text-color;
    }
    .ql-snow .ql-stroke {
      stroke: $main-text-color;
    }
    .ql-snow .ql-picker {
      color: $main-text-color;
    }
    .ql-snow .ql-picker-options {
      background-color: $form-control-bg-color;
    }

    .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
      color: $secondary-color;
    }
    .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
      stroke: $secondary-color;
    }
    .ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
      fill: $secondary-color;
    }
}


/* --------------------------- */
.search-box {
  background-color: $header-searchbox-bg-color; //#FFF;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 40px;

  .icon {
    font-size: 12px;
    margin-right: 8px;
    color: $text-color;
  }
  input {
    font-size: 14px;
    line-height: 1.2;
    width: 100px;
    border: none;
    background: transparent;
    color: $text-color;

    &:focus {
      outline: 0;
    }

    &::-webkit-input-placeholder {
      font-size: 12px;
      color: rgba($text-color, .5);
    }

    &::-ms-input-placeholder {
      font-size: 12px;
      color: rgba($text-color, .5);
    }
  }
}

// PANEL
.drop-panel {
  .panel-head {
    cursor: pointer;
  }
  .panel-content {
    height: 0;
    overflow: hidden;
    margin: .25em 0;
  }
  &.panel-open {
    .panel-content {
      height: auto;
    }
    .divider-arrow {
      color: $success-color;
      transform: rotate(90deg);
    }
  }
}


// HORIZONTAL FORM DIVIDER
.block-divider {
  color: $text-color;
  display: flex;
  flex-wrap: nowrap;
  .divider-title {
    margin-right: 10px;
    flex-basis: auto;
    font-size: 1.4rem;
    font-weight: 600;
    // color: $main-color;
    user-select: none;
  }
  .divider-line {
    background-color: rgba($dark-gray, .6); // $light-gray;
    flex-grow: 1;
    height: 2px;
    margin: auto 0;
  }
  .divider-arrow {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    // color: $main-color;
    font-size: 1rem;
    transition: all .15s ease-in-out;
  }
}

// MODAL INLINE FORM
.inline-form-info {
  padding-bottom: 10px;

  h3 {
      border-bottom: 2px solid $light-gray;
      font-weight: 400;
      padding: 0 5px 5px;
      margin-bottom: 10px;
  }
}
.ui-inline-control {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: 0;
    margin-bottom: 5px;
    padding: 8px 10px;

    .input-label {
        flex: 0 0 30%;
        max-width: 30%;
        white-space: normal;
        // overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
    }

    > strong {
        flex: 0 0 70%;
        font-size: 14px;
    }

    textarea {
      resize: none;
    }

    .input-control {
        // flex: 0 0 70%;
        flex: 1 1 100%;
        max-width: 70%;

        &.ui-box {
          padding-bottom: 0;
          // justify-content: flex-end;
        }
        & + .btn {
          height: 34px;
          margin-left: 10px;
        }
    }
    &:nth-of-type(odd) {
        background: rgba($text-color, .05);
    }
}

// SHOW PASSWORD EYE
.password-show {
    position: absolute;
    right: 20px;
    background-color: #d2cfcf;
    top: 25%;
    width: 14px;
    height: 14px;
    border-radius: 75% 15%;
    transform: rotate(45deg) translateY(-25%);
    cursor: pointer;
    &.active {
        background-color: $main-color;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border: solid 2px #fff;
        border-radius: 50%;
        left: 4px;
        top: 4px;
    }
}

/* --------------------------- */
.user-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px 5px 10px;
  cursor: pointer;
  position: relative;

  .u-avatar {
    .u-image {
      width: 55px;
      max-height: 55px;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  .u-details {
    color: $text-color;
    .u-head {
      font-size: 18px;
      font-weight: 600;
    }
    .u-body {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.2;
    }
    .u-foot {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.2;
    }
  }

  &.active {
    background-color: $main-color !important;
    .u-details {
      color: #FFFFFF;
    }
  }
}

// File-uploader
.file-uploader-input {
  .ui-inputtext {
    margin-bottom: 20px;
    padding-right: 50px;
    cursor: pointer;
  }
  .fa {
    color: $secondary-color;
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 22px;
    cursor: pointer;
    pointer-events: none;
  }
  ::-webkit-file-upload-button {
    visibility: hidden;
    width: 0;
    padding: 0;
  }
}
.file-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    width: 100%; //360px;
    height: 140px;
    background-color: $form-control-bg-color;
    color: rgba($main-color, .2);
    border: 1px solid rgba($main-color, .5);
    border-radius: 3px;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    transition: .2s;
    .icon {
        font-size: 40px;
        margin-bottom: 10px;
    }
    input[type="file"] {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        bottom: 0;
        opacity: 0;
        &::-webkit-file-upload-button {
            display: none;
        }
    }
    &:hover {
        // border-width: 2px;
        border-color: rgba($main-color, .8);
      }
    .progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

// File-preview-box
.file-preview-box {
    width: 100%; //360px;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap; // justify-content: space-between;
    // // align-items: flex-start;
    // // align-content: flex-start;
    // // height: 200px;
    // overflow-y: auto;

    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 5px;
    grid-column-gap: 0px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    margin-top: 30px;
    margin-bottom: 10px;
    .file-preview {
        position: relative; // flex: 0 0 calc(100% / 3 - 5px);
        // flex: 0 0 calc(100% / 4); // height: calc(50% - 10px);
        // height: 15vw;
        height: 250px;
        margin-bottom: 15px;
        padding: 0 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .file-container {
            flex: 0 1 100%;
            // height: calc(100% - 20px);
            text-align: center;
            background: $light-gray;
            margin: 2.5px; // box-shadow: 0 0 2px 0px rgba(#eee, 0.4)
            // border-radius: 1px;
            overflow: hidden;
            cursor: pointer;
            user-select: none;
            box-shadow: 0 0 0 2px $light-gray;
            position: relative;
            &.active {
                // border: 3px solid $success-color;
                box-shadow: 0 0 0 2px $success-color;
            }
            .file-image {
                height: 100%;
                // width: auto;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -75%);
                font-size: 22px;
                color: $main-color;
            }
            .progress-bar {
              position: absolute;
              bottom: 0;
            }
        }
        .file-name {
            font-size: 12px;
            line-height: 1;
            height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba($text-color, .75);
        }
        .file-options {
            display: none;

            position: absolute;
            top: 3px;
            right: 13px;
            padding: 6px;
            // line-height: 1;
            // font-size: 12px;
            // color: #fff;
            // background-color: rgba(0, 0, 0, 0.2);
            // cursor: pointer;
            // .icon:hover {
            //     box-shadow: 0 0 4px 0px $main-color;
            //     border-radius: 50%;
            // }
            .btn {
              min-width: auto;
              height: 30px;
              line-height: 30px;
            }
            a:hover {
              color: #fff;
              text-decoration: none;
            }
        }

        &:hover {
          .file-container {
            opacity: .75;
          }
          .file-options {
            display: block;
          }
        }
    }
}
.lightboxOverlay {
  width: 100vw !important;
  height: 100vh !important;
}



// LEGEND
.levels-block {
  position: relative;
  width: 200px;
  padding: 10px 10px 10px 15px;
  background: $main-color;
  box-shadow: 0 0 5px 0 rgba($main-color, 0.1);

  button.btn {
    display: block;
    width: 100%;
    padding-left: 50px;
    padding-right: 10px;

    text-align: right;
    text-transform: uppercase;
    background-color: transparent;
    // border-radius: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
    color: rgba($light-gray, .75);

    z-index: 2;

    &:hover {
      background: transparent !important;
    }
    &:active {
      box-shadow: none;
    }

    &.active {
      border: 1px solid rgba($light-gray, .75);
      font-weight: 600;
      font-style: italic;
      color: #fff;
    }
  }

  .levels-rainbow {
    position: absolute;
    top: 10px;
    left: 15px;
    bottom: 10px;
    width: 40px;
    background: linear-gradient(
      to bottom,
      rgba($error-color, .9),
      rgba($warn-color, .9),
      rgba($yellow-color, .9),
      rgba($success-color, .9),
      rgba($aqua-color, .9)
    ) 0  100%;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 100%;
      border-top: 200px solid transparent;
      border-right: 30px solid $main-color;
    }
  }

}

// RATING STARS
.rating-stars {
  span {
    position: relative;
    font-size: 34px;
    color: $main-color;
    line-height: 1;
    &.active-star::before {
      content: "\2605";
      position: absolute;
    }
  }
}

// MINI QUESTION MARK USED TO EXPLAIN SOMETHING
// WHEN USER HOVER OVER IT
.title-explanation {
  font-size: 12px;
  vertical-align: super;
  padding: 4px;
  margin-left: -4px;
}
.tooltip-explanation.ui-tooltip {
  min-width: 450px;
  padding: 12px;
  .ui-tooltip-text {
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.4;
  }
}




// DASHBOARD / DASHBOARD ITEM
.dashboard-item-condition {
  table {
    width: calc(100% - 20px);
    margin: 0 auto;
    font-size: 80%;
    tr {
      border-bottom: 1px solid rgba($text-color, .05);
      &:first-of-type {
        border-top: 1px solid rgba($text-color, .05);
      }
    }
    td {
      line-height: 1.3;
    }
  }
}









/* Progress Bars */
.progress-bar {
    position: relative;
    width: 100%;
    height: 5px;
    background-color: $text-color;
    overflow: hidden;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    .move {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: repeating-linear-gradient( -30deg, transparent 0px, transparent 31px, $secondary-color 30px, $secondary-color 55px);
        background-size: 100px 100px;
        animation: move 1s linear infinite;
    }
    .indeterminate {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            background-color: $secondary-color;
            animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            background-color: $secondary-color;
            animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.15s;
        }
    }
    .determinate {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: $secondary-color;
        transition: width .3s linear;

        &.success {
          background-color: $success-color;
        }
        &.error {
          background-color: $error-color;
        }
    }
}

@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100px 0;
    }
}

@-webkit-keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@-webkit-keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}


/*
 ======================================================================
 * GLOBAL RESPONSIVENESS
 * Extra small <576px       @media (max-width: 575px){ }
 * Small ≥576px             @media (min-width: 576px){ }
 * Medium ≥768px            @media (min-width: 768px){ }
 * Large ≥992px             @media (min-width: 992px){ }
 * Extra large ≥1200px      @media (min-width: 1200px){ }

 * PRIME-NG GRID
 * Extra small ui-sm-*      @media (max-width: 40em (640px)) { }
 * Medium ui-md-*           @media (min-width: 40.063em (641px)) { }
 * Large ui-lg-*            @media (min-width: 64.063em (1025px) { }
 * Extra large ui-xl-*      @media (min-width: 90.063em (1441px)) { }
 ======================================================================
 */

@media (max-width: 575px){
  .content,
  .multiple-content .content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .hidden-sm {
    display: none !important;
  }
  .file-uploader, .file-preview-box {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .show-sm {
      display: none !important;
  }
}

@media (min-width: 768px) {}

@media (max-width: 992px) {
  .hidden-md {
    display: none !important;
  }
  .multiple-content {
    height: calc(100% - 80px);
    overflow-y: auto;
    flex-direction: column;

    .content {
      min-height: 100%;
      padding: $page-spacing;
    }
  }

  .content-header {
    height: auto;
    flex-wrap: wrap;
    padding-bottom: 5px;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
@media (min-width: 992px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: 1200px) {}
