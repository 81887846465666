/*
 =========================================================
 * CARDS TEMPLATE and DESIGN

  <!-- <div class="card card-size__2x card-has-footer">
    <div class="card-header">
      <h3 class="ch-title">
        Header
      </h3>
      <div class="ch-options">
        <button class="btn btn-icon">
          <span [appIcon]="'tt-icon'"></span>
        </button>
        <button class="btn btn-icon" (click)="op.toggle($event)">
          <span [appIcon]="'tt-more'"></span>
        </button>
      </div>
    </div>

    <div class="card-body">

    </div>

    <div class="card-footer">
      <div class="cf-title">
        Footer title
      </div>
      <div class="cf-options">
        Agreee
      </div>
    </div>
  </div> -->
 =========================================================
 */

.card {
  $card-height: 270px;

  position: relative;
  background-color: $content-bg-color;
  background: $content-bg;
  box-shadow: $shadowBottom;
  margin-bottom: $page-spacing; //20px;
  border-top: 2px solid $secondary-color;
  border-radius: 2px;
  height: $card-height; //265px;
  overflow: hidden;
  .card-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    // height: 50px;
    height: $content-header-height;
    min-height: $content-header-height;
    // border-bottom: 2px solid rgba($light-gray, .75);
    background-color: $content-header-bg-color;
    // padding-top: 8px;
    // margin: 0 20px;
    padding: 0 5px;
    .ch-container {
      display: flex;
      // align-items: center;
    }
    .ch-title {
      color: $text-color;
      // font-weight: 600;
      // margin-top: 6px;
      font-size: 1.7rem;
      font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      font-weight: 400;
      text-transform: capitalize;
      align-self: center;
      margin-bottom: 0;
      padding: 5px 10px;
      label {
        margin-bottom: 0;
      }
    }
    .ch-options {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      // margin-right: -15px;
      .btn {
        color: rgba($text-color, 0.85);
        background-color: transparent;
        .icon,
        .fa {
          color: inherit;
        }
        &:hover:not(:disabled) {
          background-color: $default-btn-active !important;
        }
      }
    }
  }
  .card-body {
    position: relative;
    height: calc(100% - (#{$content-header-height} + 10px)); // header height + margin-top-bottom
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px 10px 10px 20px;
    // margin-right: 10px;
    // margin-bottom: 10px;
    margin: 5px 10px 5px 0;
    &::-webkit-scrollbar-thumb {
      background-color: $custom-scrollbar-color;
      border-radius: 4px;
    }
  }
  .card-footer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: $main-color;
    background-color: rgba($main-color, 0.2);
    border: 2px solid $main-color;
    // color: #fff;
    color: rgba($text-color, 0.75);
    padding: 0px 20px;
    height: $footer-height;
    .cf-title {
      font-size: 14px;
    }
    .cf-options {
      font-size: 14px;
      font-weight: 900;
      .btn {
        color: rgba($dark-gray, 0.75);
        font-size: inherit;
        font-weight: inherit;
        .icon {
          font-size: 8px;
        }
      }
    }
    &.card-footer-negligible {
      background-color: rgba($aqua-color, 0.2);
      border: 2px solid $aqua-color;
    }
    &.card-footer-success {
      background-color: rgba($success-color, 0.2);
      border: 2px solid $success-color;
    }
    &.card-footer-medium {
      background-color: rgba($yellow-color, 0.2);
      border: 2px solid $yellow-color;
    }
    &.card-footer-high {
      background-color: rgba($warn-color, 0.2);
      border: 2px solid $warn-color;
    }
    &.card-footer-veryHigh {
      background-color: rgba($error-color, 0.2);
      border: 2px solid $error-color;
    }
    &.card-footer-notRated {
      background-color: rgba($dark-gray, 0.2);
      border: 2px solid $dark-gray;
    }
  }

  // card size subclasses
  &.card-size__1_5x {
    height: #{$card-height * 1.5 + (15px * 0.5)}; //400px;
  }
  &.card-size__2x {
    height: #{$card-height * 2 + 15px}; //550px
  }
  &.card-size__2_5x {
    height: #{$card-height * 2.5 + (15px * 1.5)}; //665px;
  }
  &.card-size__3x {
    height: #{$card-height * 3 + (15px * 2)}; //795px;
  }
  &.card-has-footer {
    .card-body {
      height: calc(
        100% - (#{$content-header-height} + #{$footer-height} + 10px + 2px)
      ); // header + margin-top-bottom(card-body) + footer height + 2px (border-top)
    }
  }

  button {
    // every button in card!!!
    height: 28px;
    min-width: 28px !important;
    line-height: 1;
  }
  .icon.tt-more {
    color: rgba($dark-gray, 0.75);
    font-size: 1.4rem;
  }

  /* ------------------------------------------------------------------------ */
  /* RISK DASHBOARD */

  .cb-risk {
    padding: 0 10px;
    .cb-risk-head {
      display: flex;
      justify-content: space-between;
      .cb-risk-head-controls {
        flex: 0 1 40%;
      }
    }
    .cb-risk-body {
      position: relative;
      margin: 10px 0 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .risk-widget {
        position: relative;
        padding: 10px;
        border-radius: 50%;
        background-color: $light-gray; // default color of widget
        > div {
          border: 3px solid #fff;
          border-radius: 50%;
          height: 175px;
          width: 175px;
          color: $dark-gray;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span {
          font-size: 18px;
          font-weight: 600;
          color: #fff; // #405364;
          text-transform: capitalize;
        }
        label {
          position: absolute;
          top: 35px;
          left: 50%;
          transform: translateX(-50%);
          color: #fff; // $dark-gray;
          font-size: 12px;
          transition: 0.3s ease all;
          &.edit-mode {
            top: -50px;
            color: $dark-gray;
          }
        }
      }
      .inherent {
        margin-right: -30px;
      }
      .forecast {
        margin-left: -30px;
      }
      .residual {
        padding: 12px;
        // background: #fff;
        box-shadow: 0 0 1px 0 $light-gray;
        border-radius: 50%;
        z-index: 2;
        > div {
          height: 240px;
          width: 240px;
        }
        span {
          font-size: 20px;
        }
        label {
          top: 50px;
          &.edit-mode {
            top: 290px;
          }
        }
      }
    }
    .cb-risk-footer {
      display: flex;
      justify-content: center;
      align-content: center;
      > div {
        flex: 0 1 40%;
      }
    }
  }

  /* ------------------------------------------------------------------------ */

  .card-info {
    margin-bottom: 10px;
  }

  .info-text {
    font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: $text-color;
    margin: 0;
  }

  .block-text {
    color: $text-color;
    background-color: rgba($main-color, 0.075);
    padding: 6px 10px;
    border-radius: 2px;
  }

  /* --------------------------- */
  .info-positions {
    font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif;
    .position-label,
    .position-title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      span {
        flex: 0 1 100%;
      }
    }
    .position-label span {
      color: rgba($text-color, 0.75);
      font-size: 12px;
      line-height: 1.5;
    }
    .position-title span {
      color: $text-color;
    }
    .separator {
      height: 1px;
      width: 70%;
      background: rgba($text-color, 0.1);
      margin: 10px 0;
    }
  }

  .card-separator {
    height: 1px;
    width: 70%;
    background: rgba($text-color, 0.1);
    margin: 10px 0;
  }

  /* --------------------------- */
  .card-block-widget {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $text-color;
    border-radius: 2px;
    border: 2px solid transparent;
    background-color: $light-gray; // default color of widget
    label {
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 0;
    }
    span {
      font-size: 14px;
      font-weight: 900;
      padding-top: 4px;
    }
    &.type-veryHigh {
      border-color: $error-color;
      background: rgba($error-color, 0.2);
    }
    &.type-high {
      border-color: $warn-color;
      background: rgba($warn-color, 0.2);
    }
    &.type-medium {
      border-color: $yellow-color;
      background: rgba($yellow-color, 0.2);
    }
    &.type-low {
      border-color: $success-color;
      background: rgba($success-color, 0.2);
    }
    &.type-negligible {
      border-color: $aqua-color;
      background: rgba($aqua-color, 0.2);
    }
  }

  /* --------------------------- */
  .card-list {
    .card-list-item {
      color: $text-color;
      line-height: 1.25;
      padding: 2px 4px;
      margin-bottom: 4px;
      border-bottom: 1px solid rgba($dark-gray, 0.1);
      cursor: pointer;
    }
  }

  /* --------------------------- */
  .block-control {
    padding: 4px 8px;
    .block-control-label {
      font-size: 12px;
      color: rgba($text-color, 0.75);
    }
    .block-control-title {
      color: $text-color;
      font-weight: 600;
    }
  }

  /* --------------------------- */
  .card-table {
    .card-table-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      padding: 5px 10px;
      color: $text-color;
      font-size: 12px;
      font-weight: 600;
      // cursor: pointer;
      .col-title {
        flex: 0 1 50%;
      }
      .col-user {
        flex: 0 1 30%;
        span {
          display: inline-block;
          min-width: 130px;
          border: 1px solid rgba(52, 64, 77, 0.25);
          border-radius: 14px;
          padding: 4px 8px;
          text-align: center;
        }
      }
      .col-option {
        flex: 0 1 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > span {
          min-width: 110px;
          margin-right: 8px;
          border-style: solid;
          border-width: 2px;

          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding: 3px 8px;

          span {
            min-width: 60%;
          }
        }
      }
      &:nth-child(odd) {
        background-color: rgba($main-color, 0.075);
      }
    }
  }

  .card-spacing {
    margin-bottom: 10px;
    padding: 10px 10px 0;
    overflow: hidden;
  }

  /* --------------------------- */
  .inner-card {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 134px;
    overflow: hidden;
    margin-bottom: 20px;
    // box-shadow: 0 0px 6px 2px rgba(0, 0, 0, 0.1);
    box-shadow: $shadowMain;
    background: $content-bg-color;

    .card-info {
      flex: 0 0 60%;
      padding: 10px 10px 0;
      overflow: hidden;
    }
    .card-options {
      flex: 0 0 40%;
      margin-bottom: 10px;
      padding: 10px 10px 0;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .card-block-widget {
        flex: 0 0 100%;
        height: 64px;
      }
    }
    .ch-options {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      margin-right: -6px;
      .btn {
        .icon:not(.tt-more) {
          color: $main-color;
        }
        &:hover:not(:disabled) {
          background-color: $default-btn-active !important;
        }
      }
    }

    .line-clamp-4 {
      display: block;
      display: -webkit-box;
      height: calc(14px * 1.5 * 4);
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
    &.type-veryHigh {
      border-left: 15px solid $error-color;
    }
    &.type-high {
      border-left: 15px solid $warn-color;
    }
    &.type-medium {
      border-left: 15px solid $yellow-color;
    }
    &.type-low {
      border-left: 15px solid $success-color;
    }
    &.type-negligible {
      border-left: 15px solid $aqua-color;
    }
  }

  .btn-risk-sorting {
    // used in risk parent and child
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 10px;
    }
  }

  /* --------------------------- */
  .search-box {
    height: 22px;
    margin-right: 16px;
    margin-top: 4px;
    color: $main-color;
    // background-color: #FFF;
  }

  /* --------------------------- */
  .toggle-inner {
    button {
      font-size: 12px;
      opacity: 0.3;
      transition: $layoutTransition;
      &.toggle-active {
        opacity: 1;
      }
      &:active:not(:disabled) {
        box-shadow: none;
      }
      .circle {
        // display: inline-block;
        height: 15px;
        width: 15px;
        // margin-bottom: -2px;
        border-radius: 50%;
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  /* --------------------------- */
  .shadow-block {
    margin-bottom: 20px;
    box-shadow: $shadowMain; //0 0px 6px 2px rgba(0, 0, 0, 0.1);
    .shadow-block-content {
      padding: 5px 10px;
    }
    .shadow-block-head {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px 0;
    }
    .shadow-block-body {
      padding: 10px 10px 0;

      > .info-text {
        min-height: 50px;
        // display: block;
        // display: -webkit-box;
        // height: calc(14px * 1.5 * 5);
        // -webkit-line-clamp: 5;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
    .shadow-block-footer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      padding: 0px 10px 10px;
      > * {
        margin: 0;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  /* --------------------------- */
  .card-notes {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 15px 0;
    border-bottom: 1px solid $light-gray;
    .notes-head {
      flex: 0 1 20%;
      color: $text-color;
      > * {
        margin-bottom: 0;
      }
    }
    .notes-desc {
      flex: 0 0 80%;
      font-size: 14px;
      font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif;
      line-height: 1.3;
      padding: 0 10px;
      // color: rgba($text-color, .75);

      // display: block;
      // display: -webkit-box;
      // height: calc(14px * 1.3 * 4); // font-size * line-height * line-clamp
      // -webkit-line-clamp: 4;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }

  .card-noDataMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    user-select: none;
    &:active {
      opacity: 0.75;
    }
  }

  .card-activity {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    > div {
      flex: 0 0 23%;
      padding: 10px 0;
    }
    .activity-info {
      padding: 2px 2px 6px;

      span {
        font-size: 14px;
        display: inline-block;

        &:not(.text-error):not(.icon) {
          color: $text-color;
        }
        &.text-error {
          font-weight: 600;
          min-width: 25px;
        }
        &.icon {
          font-size: 16px;
          color: $success-color;
          margin-right: 10px;
        }
      }
    }

    .activity-welcome {
      h2 {
        color: $text-color;
        border-bottom: 1px solid $light-gray;
      }
      .btn {
        margin-top: 30px;
        height: 50px;
        width: 100%;
      }
    }
    .activity-suggestions {
      border-right: 1px solid rgba($light-gray, 0.5);
      h4 {
        color: $text-color;
      }
    }
    .activity-records {
      h6 {
        // color: $dark-gray;
        font-weight: normal;
      }
      .activity-info {
        .icon {
          color: $text-color;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .card {
    height: auto !important;

    .card-table .card-table-row {
      flex-wrap: wrap;
      justify-content: space-between;
      .col-title {
        flex: 0 0 100%;
      }
    }

    &._mb0 {
      margin-bottom: 20px !important;
    }
  }
}

@media (max-width: 992px) {
  .card {
    // .card-header {
    //     height: auto;
    //     // flex-wrap: wrap;
    // }
    .inner-card {
      flex-direction: column;
      height: auto;
      position: relative;
      .card-info .info-text {
        padding-right: 30px;
        margin-bottom: 10px;
      }
      .card-options + .card-spacing .fx-bw-col {
        flex-direction: row;
        justify-content: space-around;
      }
      .ch-options {
        position: absolute;
        top: 5px;
        right: 10px;
      }
    }
    .card-notes {
      flex-wrap: wrap;
      .notes-head {
        flex: 0 0 100%;
        order: 3;
        display: flex;
        justify-content: space-between;
      }
      .notes-desc {
        padding-left: 0;
        flex-grow: 1;
        margin-bottom: 10px;
      }
    }
    .fx-bw-row:not(._ignore-device) {
      flex-direction: column;
    }

    .card-activity {
      flex-direction: column;

      .activity-suggestions {
        border-right-width: 0;
      }
    }
  }

  .--card-md {
    .card-header {
      min-height: $content-header-height * 2;
      flex-direction: column;
      justify-content: space-evenly;

      .ch-options {
        justify-content: space-between;
      }
    }
    .card-body {
      height: calc(100% - ((#{$content-header-height} * 2) + 10px));
    }
  }
}
