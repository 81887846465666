.navigation-bar-wrapper {
  $icon-padding: 20px;
  $company-logo-height: 50px;

  display: block;
  height: 100%;
  position: relative;
  
  ::-webkit-scrollbar {
    display: none;
  }


  .nav {
    height: calc(100% - #{$company-logo-height});
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $navbar-nav-bg-color;
    -ms-overflow-style: none;
    
    // global for all levels
    .nav-list {
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      z-index: 1;

      li {
        z-index: 2;
        position: relative;
        background-color: transparent;

        &.expanded {
          transition: .25s all ease-in-out;
          > a span.menuItem-arrow {
            transform: rotate(90deg);
            color: $secondary-color!important;
          }
        }
      }

      a {
        text-decoration: none;
        padding: 5px 10px 5px $icon-padding;
        height: $navbar-item-height;
        
        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: 1.4rem;
        line-height: 1.165;
      }

      p {
        flex: 1 1 auto;
        margin: 0;
        color: $navbar-color;
        
        display: flex;
        align-items: center;
        position: relative;
        transition: .15s 0s all ease-out;

        padding-right: 4px;
        word-break: break-word;
      }
      // span.menuItem-icon {
      //   margin-right: 8px;
      //   transition: .15s 0s all ease-out;
      // }
      span.menuItem-name {
        margin-left: 8px;
        transition: .15s .15s opacity ease-out;
        opacity: 1;
      }
      span.menuItem-arrow {
        transition: .25s all ease-in-out;
        color: $navbar-line-color;
        height: auto;
        font-size: 10px;
        flex: 0 0 10px;
      }

      // has class show-sm
      .nav-options {
        padding: 15px;
        a {
          padding: 0;
          height: auto;
        }
        .menuItem-name {
          font-size: 18px;
          font-weight: 600;
          margin-left: 0;
        }
        .menuItem-arrow {
          font-size: 20px;
          color: #fff;
        }
    
        .search-box {
          height: auto;
          display: flex;
          align-items: center;
          padding: 8px 12px;
          border-radius: 40px;
          margin-right: 0;
          margin-top: 15px;
      
          .icon {
            font-size: 12px;
            margin-right: 8px;
          }
          input {
            font-size: 14px;
            line-height: 1.2;
            border: none;
            width: 100%;
      
            &:focus {
              outline: 0;
            }
      
            &::-webkit-input-placeholder {
              font-size: 12px;
              color: rgba($main-color, .5);
            }
          }
        }
        
      }
    }

    // Level 1
    .list-1 {
      > li:first-of-type {
        border-top-width: 0;
      }
      > li:last-of-type {
        border-bottom: 1px solid $navbar-border-color;
      }
      > li {
        border-left: 4px solid transparent;
        
        > a {
          border-top: 1px solid $navbar-border-color;
        }
        &.active {
          background-color: $navbar-first-level-bg-color;
          border-left-color: $secondary-color;
          box-shadow: 0 1px 5px 0 rgba(1, 16, 29, 0.3);
        }
      }
    }

    // Level 2
    .list-2 {
      > li.expanded {
        background-color: $navbar-second-level-bg-color;
        &.active {
          > a::before {
            box-shadow: 0 0 0px 2px $secondary-color;
          }
        }
      }
      > li {

        > a {
          padding-left: $icon-padding * 2;
        }
      }
    }

    // Level 3
    .list-3 {
      > li {

        > a {
          padding-left: $icon-padding * 3;
          height: $navbar-item-height - 10px;
        }
      }
    }

  // END NAV
  }


  // MAP-LEVEL
  .list-1 {
    .list-2 li {
      &::before {
        content: " ";
        background: linear-gradient(to right, $navbar-line-color, $navbar-line-color) no-repeat 0 0;
        background-size: 0 2px;
        width: 12px;
        height: 2px;
        position: absolute;
        left: 8px;
        top: calc(#{$navbar-item-height} / 2);
        transform: translateY(-50%);
        opacity: 0;
        transition: .15s .150s background-size ease-in-out, .15s width ease-in-out, .15s left ease-in-out;
        pointer-events: none;
      }
      
      a::after {
        content: "";
        background: linear-gradient(to bottom, $navbar-line-color, $navbar-line-color) no-repeat 0 0;
        background-size: 2px 0;
        height: 100%;
        width: 2px;
        position: absolute;
        left: 8px;
        top: calc(#{$navbar-item-height} / -2);
        opacity: 0;
        transition: .15s 0s height ease-in, .15s .15s background-size ease-in-out, .15s left ease-in-out;
        z-index: 2;
        pointer-events: none;
      }
    }
    > li.expanded {
      background-color: $navbar-first-level-bg-color;
      
      a::before {
        content: " ";
        width: 8px;
        height: 8px;
        background-color: $navbar-line-color;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        top: calc(#{$navbar-item-height} / 2);
        transform: translateY(-50%);
        z-index: 3;
        transition: .15s all ease-in-out;
        pointer-events: none;
      }
      &.active {
        > a::before {
          background-color: $secondary-color;
        }
      }

      .list-2 > li {
        &::before {
          background-size: 100% 2px;
          opacity: 1;
        }

        > a::after {
          background-size: 2px 100%;
          opacity: 1;
        }
        &:last-of-type > a::after {
          height: $navbar-item-height;
        }
  
        > a::before {
          left: 20px;
        }
        &.active > a::before {
          background-color: $secondary-color;
        }

        .list-3 > li {
          &::before {
            width: 20px;
            left: $icon-padding + 3px;
            top: 50%;
          }
    
          > a::before {
            left: $icon-padding * 2 + 3px;
            top: 50%;
          }
          &.active > a::before {
            background-color: $secondary-color;
          }
        }

        &.expanded { // .list-2 > li.expanded
          .list-3 > li {
            &::before {
              background-size: 100% 2px;
              opacity: 1;
            }
            &:first-of-type > a::after {
              top: calc((#{$navbar-item-height} - 4px) / -2);
              height: $navbar-item-height - 6px;
            }
            > a::after {
              top: calc((#{$navbar-item-height} - 12px) / -2);
              left: $icon-padding + 3px;
              background-size: 2px 100%;
              opacity: 1;
            }
          }
        }

      }

    }
  }

  .company-logo {
    position: absolute;
    bottom: 0;
    
    width: 100%;
    height: $company-logo-height;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 6px 0px 8px 0px;
    box-shadow: $shadowTop;

    > div {
      opacity: .5;
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .cl-mini {
      background-image: $navbar-company-logo-mini;
    }
    .cl-normal {
      background-image: $navbar-company-logo-normal;
    }
    .cl-mini { display: none; }
  }
  

}

// body
.navbar-closed {
  .navigation-bar-wrapper {
    .nav-list li:not(.nav-options) a {
      p { word-break: initial; }
      .menuItem-name, .menuItem-arrow { 
        transition: 0s 0s opacity ease-out;
        opacity: 0;
      }
    }

    .list-1 > li.expanded {
      .list-2 > li {
        &::before {
          width: 8px;
        }
        > a::before {
          left: 16px;
        }
        a {
          padding-left: 30px; 
        }
        .list-3 > li {
          &::before {
            left: 19px;
            width: 14px;
          }
          a::before {
            left: 33px;
          }
          a::after {
            left: 19px;
          }
        } 
      }
    }

    .company-logo {
      padding: 0;
      .cl-mini { display: block; width: 75%; }
      .cl-normal { display: none; }
    }
  
    @media (min-width: 992px) {
      li:hover {
        z-index: 8;
        > a, > a > p {z-index: 9}
        // > a:hover .menuItem-name {
        .menuItem-name._hovering {
          pointer-events: none;
          display: block;
          opacity: 1 !important;
          position: fixed;
          // left: 10px;
          left: $navbar-closed-width;
          // margin-top: 30px;
          color: $main-color;
          background-color: $light-gray;
          font-size: 14px;
          padding: 2px 12px;
          border: 1px solid $secondary-color;
          border-radius: 2px;
          // min-width: 120px;
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right: 6px solid $secondary-color;
            left: -6px;
            top: 2px;
            // border-right: 6px solid transparent;
            // border-left: 6px solid transparent;
            // border-bottom: 6px solid $secondary-color;
            // left: 6px;
            // top: -6px;
          }
        }
      }
      // .list-2 li:hover {
      //   > a:hover .menuItem-name { left: 20px; }
      // }
    }
  }
}