/*
 =========================================================
 * CUSTOM POST PAGE DESIGN
 =========================================================
 */

 .post-page {
  .content-inner._has-header {
    height: calc(100% - (#{$page-spacing}) * 2);
  }
  
  .content-body {
    // margin-top: 10px;
    // height: calc(100% - 60px);
    /* margin-top: 10px; */
    height: calc(100% - #{$content-header-height});
  
    &:not(.content-tabs-inner) {
      padding: 10px 10px 10px 20px;
      overflow-y: auto;
    }
    .tab-section {
      height: 100%;
    }
  }
  
  .langagues {
    line-height: 34px;
    padding-right: 50px;
  
    label {
      padding-left: 3px;
      margin: 0;
      cursor: pointer;
      color: $dark-gray;
  
      &.active {
        font-weight: bold;
        color: $text-color;
      }
  
      &::after {
        content: ' / ';
        font-weight: 400;
        color: $dark-gray;
      }
      &:last-child::after {
        content: '';
      }
    }
  }
  
  .gmap {
      width: 100%;
      height: 20em;
      margin: 10px 0;
  }
  
  .postHistory {
      padding: 15px 0;
      .history {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: .5em;
          .upper {
              font-size: 1em;
              color: $dark-gray;
              label {
                  font-weight: bold;
                  color: $text-color;
                  margin-bottom: 0;
              }
          }
          .lower {
              color: $dark-gray;
              font-size: .7em;
          }
      }
      .moreInfo {
          opacity: .8;
          text-align: right;
          color: $dark-gray;
          font-size: 1em;
          position: relative;
          label {
              cursor: pointer;
              padding-right: 2em;
              position: relative;
              i {
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  color: $text-color;
                  font-size: 1.7em;
              }
          }
      }
  }
}

  
.copy-post {
 display: flex;
 width: 250px;
 justify-content: space-between;
 align-items: center;
 border-bottom: 1px solid rgba(0, 0, 0, 0.1);
 padding: 5px 15px 10px;
}

.post-page-history-modal {
   max-height: 75vh;
   overflow-y: scroll;
   .history_item {
       box-sizing: border-box;
       width: 100%;
       display: flex;
       flex-direction: row;
       flex-wrap: nowrap;
       justify-content: center;
       align-content: stretch;
       align-items: flex-start;
       padding: 1em .5em 1em 3em;
       margin-bottom: 4px;
       border: 1px solid transparent;
       cursor: pointer;
       position: relative;

       &.odd {
           background-color: rgba($light-gray, 75);
       }
       &.active {
           border-color: $secondary-color;
           >i.fa {
               color: $secondary-color;
           }
       }
       >i.fa {
           color: $dark-gray;
           font-size: 1.2em;
           position: absolute;
           top: 50%;
           left: .7em;
           transform: translateY(-50%);
       }
       .date_info {
           order: 0;
           flex: 0 1 25%;
           align-self: auto;
           display: flex;
           flex-direction: column;
           flex-wrap: nowrap;
           justify-content: space-around;
           align-content: flex-start;
           align-items: flex-start;
           label {
               order: 0;
               flex: 0 1 auto;
               align-self: auto;
               margin-bottom: 0;
               &.date {
                   font-size: 1em;
                   color: $main-color;
               }
               &.time {
                   font-size: .7em;
                   color: $dark-gray;
               }
               &.state {
                   text-align: center;
                   color: $dark-gray;
                   font-size: 1.2em;
               }
           }
       }
       .main_info {
           order: 0;
           flex: 0 1 75%;
           align-self: auto;
           font-size: 1em;
           color: $dark-gray;
           label {
               font-weight: bold;
               color: $main-color;
               margin-bottom: 0;
           }
       }
   }
}