/*
 =========================================================
 * CLASSES TO FIX OR HELP SOME MAIN CLASS
 =========================================================
 */

// CUSTOM QUICK FLEX BOXES
.fx-bw-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fx-bw-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

[class*="_fx"] {
    flex-grow: 0;
    flex-shrink: 0;
}

._fx5 {
    flex-basis: 5% !important;
}

._fx10 {
    flex-basis: 10% !important;
}

._fx15 {
    flex-basis: 15% !important;
}

._fx20 {
    flex-basis: 20% !important;
}

._fx25 {
    flex-basis: 25% !important;
}

._fx30 {
    flex-basis: 30% !important;
}

._fx35 {
    flex-basis: 35% !important;
}

._fx40 {
    flex-basis: 40% !important;
}

._fx45 {
    flex-basis: 45% !important;
}

._fx50 {
    flex-basis: 50% !important;
}

._fx55 {
    flex-basis: 55% !important;
}

._fx60 {
    flex-basis: 60% !important;
}

._fx65 {
    flex-basis: 65% !important;
}

._fx70 {
    flex-basis: 70% !important;
}

._fx75 {
    flex-basis: 75% !important;
}

._fx80 {
    flex-basis: 80% !important;
}

._fx85 {
    flex-basis: 85% !important;
}

._fx90 {
    flex-basis: 90% !important;
}

._fx95 {
    flex-basis: 95% !important;
}

._fx100 {
    flex-basis: 100% !important;
}

._fxauto {
    flex-basis: auto !important;
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
}

._justify-between {
    justify-content: space-between !important;
}

._justify-center {
  justify-content: center !important;
}

._justify-start {
    justify-content: flex-start !important;
}

._justify-end {
    justify-content: flex-end !important;
}
._align-center {
  align-items: center !important;
}

// *****************************************
// BACKGROUND - COLOR
._transparent {
    background-color: transparent!important;
}
._light-bg {
  background-color: $light-gray;
}

.text-primary {
    color: $text-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-success {
    color: $success-color !important;
}

.text-error {
    color: $error-color !important;
}

.text-warn {
    color: $warn-color !important;
}

.text-light {
    color: $light-gray !important;
}

.text-dark {
    color: $dark-gray !important;
}

// TEXT - ALIGNMENT
.text-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

// *****************************************
// HELPERS
[padding] {
    padding: $page-spacing!important;
}

[no-padding] {
    padding: 0!important;
}

[padding-vertical] {
    padding: $page-spacing 0!important;
}

[padding-horizontal] {
    padding: 0 $page-spacing!important;
}

[padding-bottom] {
  padding: 0 0 $page-spacing 0!important;
}
[padding-top] {
  padding: $page-spacing 0 0 0!important;
}

[margin] {
    margin: $page-spacing!important;
}

[no-margin] {
    margin: 0!important;
}

[margin-vertical] {
    margin: $page-spacing 0!important;
}

[margin-horizontal] {
    margin: 0 $page-spacing!important;
}

// MARGIN FIXERS
._mt0 {
  margin-top: 0px!important;
}

._mr0 {
  margin-right: 0px!important;
}

._mb0 {
  margin-bottom: 0px!important;
}

._mt0 {
    margin-top: 0px!important;
}

._mr5 {
    margin-right: 5px!important;
}

._mb5 {
    margin-bottom: 5px!important;
}

._ml5 {
    margin-left: 5px!important;
}

._mt10 {
    margin-top: 10px!important;
}

._mr10 {
    margin-right: 10px!important;
}

._mb10 {
    margin-bottom: 10px!important;
}

._ml10 {
    margin-left: 10px!important;
}

._mt20 {
    margin-top: 20px!important;
}

._mr20 {
    margin-right: 20px!important;
}

._mb20 {
    margin-bottom: 20px!important;
}

._ml20 {
    margin-left: 20px!important;
}

@media print {
    .app-navbar-wrapper,
    .app-header-wrapper,
    .app-subheader-wrapper {
        display: none !important;
    }
    *[class^='ui-g'] {
        margin: 0 !important;
        width: 100vw !important;
        padding: 0 !important;
    }
    .card {
        height: auto !important;
        width: 100vw !important;
    }
    button {
        display: none !important;
    }
    html,
    body,
    * {
        overflow: scroll !important;
    }
    .content {
        height: auto !important;
        padding: 0 !important;
        margin: 0 !important;
        width: 100% !important;
    }
    .app-main-wrapper {
        padding: 0 !important;
    }
    .app-section-wrapper {
        padding: 0 !important;
        height: auto !important;
    }
    .inner-card {
        page-break-inside: avoid;
    }
    app-master {
        overflow: hidden !important;
    }
    @page {
        size: A4;
        margin-left: 0;
        margin-right: 0;
    } // page-break-before
    // page-break-after
}


/*========================================================================*/
// IE 10+
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ui-control {
    .ui-inputtext {
      min-height: 34px; }
  }
  .ui-float-label textarea:focus ~ label,
  .ui-float-label textarea.ui-state-filled ~ label {
    top:-.75em;
    font-size:12px;
  }

  .file-preview-box {
    .file-preview {
      width: 300px;
      display: inline-flex;
    }
  }
  [class*='_fx'] {
    flex-shrink: 1;
  }
  .property-report {
    .property-report-header {
      min-width: 55px !important;
    }
  }

  .inline-form-info .ui-inline-control .input-control {
    flex-shrink: 1;
  }
}