$tt-path1: "\e92a";
$tt-path2: "\e92b";
$tt-path3: "\e92c";
$tt2: "\e92d";
$tt-add-goods-path1: "\e92e";
$tt-add-goods-path2: "\e92f";
$tt-bold-text-option: "\e930";
$tt-coverage-level-path1: "\e931";
$tt-coverage-level-path2: "\e932";
$tt-coverage-level-path3: "\e933";
$tt-coverage-level-path4: "\e934";
$tt-diskette-path1: "\e935";
$tt-diskette-path2: "\e936";
$tt-diskette-path3: "\e937";
$tt-download: "\e938";
$tt-download-button: "\e939";
$tt-drag-path1: "\e93a";
$tt-drag-path2: "\e93b";
$tt-envelope2: "\e93e";
$tt-error: "\e93f";
$tt-filter-filled-tool-symbol: "\e940";
$tt-four-expand-arrows: "\e941";
$tt-Group-30-path1: "\e942";
$tt-Group-30-path2: "\e943";
$tt-Group-30-path3: "\e944";
$tt-Group-30-path4: "\e945";
$tt-Group-149-path1: "\e946";
$tt-Group-149-path2: "\e947";
$tt-Group-151-path1: "\e948";
$tt-Group-151-path2: "\e949";
$tt-icon: "\e94b";
$tt-information-icon-path1: "\e94c";
$tt-information-icon-path2: "\e94d";
$tt-information-icon-path3: "\e94e";
$tt-italicize-text: "\e94f";
$tt-link-symbol: "\e950";
$tt-list2: "\e951";
$tt-menu: "\e952";
$tt-menu-closed: "\e953";
$tt-menu-halfopen-path1: "\e954";
$tt-menu-halfopen-path2: "\e955";
$tt-menu-halfopen-path3: "\e956";
$tt-excel-path1: "\e957";
$tt-excel-path2: "\e958";
$tt-more: "\e959";
$tt-newspaper-path1: "\e95a";
$tt-newspaper-path2: "\e95b";
$tt-notification: "\e95c";
$tt-numbered-list: "\e95d";
$tt-package-for-delivery-path1: "\e95e";
$tt-package-for-delivery-path2: "\e95f";
$tt-package-for-delivery-path3: "\e960";
$tt-package-for-delivery-path4: "\e961";
$tt-Path-110: "\e962";
$tt-Path-137: "\e963";
$tt-Path-220: "\e964";
$tt-Path-221: "\e965";
$tt-printer-path1: "\e966";
$tt-printer-path2: "\e967";
$tt-printer-path3: "\e968";
$tt-right-arrow: "\e969";
$tt-right-quotation-mark: "\e96a";
$tt-search: "\e96b";
$tt-security-atenttion: "\e96c";
$tt-security-atenttion-1: "\e96d";
$tt-security-info: "\e96e";
$tt-security-info-1: "\e96f";
$tt-security-off: "\e970";
$tt-security-off-1: "\e971";
$tt-security-on: "\e972";
$tt-security-on-1: "\e973";
$tt-settings-work-tool: "\e974";
$tt-table-grid: "\e975";
$tt-text-height-filled: "\e976";
$tt-tick: "\e977";
$tt-two-columns-layout: "\e978";
$tt-warehouse-with-boxes-path1: "\e979";
$tt-warehouse-with-boxes-path2: "\e97a";
$tt-warehouse-with-boxes-path3: "\e97b";
$tt-warehouse-with-boxes-path4: "\e97c";
$tt-warehouse-with-boxes-path5: "\e97d";
$tt-warehouse-with-boxes-path6: "\e97e";
$tt-warehouse-with-boxes-path7: "\e97f";
$tt-bird: "\e900";
$tt-bubble-question: "\e901";
$tt-calendar-empty: "\e902";
$tt-camera-flip: "\e903";
$tt-chat2-01: "\e904";
$tt-chevron-down: "\e905";
$tt-chevron-left: "\e906";
$tt-chevron-right-circle: "\e907";
$tt-chevron-up: "\e908";
$tt-chip: "\e909";
$tt-color-sampler: "\e90a";
$tt-credit-card: "\e90b";
$tt-cube: "\e90c";
$tt-database: "\e90d";
$tt-display: "\e90e";
$tt-document2: "\e90f";
$tt-envelope: "\e910";
$tt-file-zip: "\e911";
$tt-fingerprint-simple-outline: "\e912";
$tt-Group-36: "\e913";
$tt-Group-37: "\e914";
$tt-list: "\e915";
$tt-lock: "\e916";
$tt-map2: "\e917";
$tt-map-marker-user: "\e918";
$tt-Montions: "\e919";
$tt-phone: "\e91a";
$tt-phoneq: "\e91b";
$tt-pin-person-012: "\e91c";
$tt-play: "\e91d";
$tt-radio-button: "\e91e";
$tt-screen-field: "\e91f";
$tt-search-1: "\e920";
$tt-send-schat-01: "\e921";
$tt-share-01: "\e922";
$tt-share3: "\e923";
$tt-shield: "\e924";
$tt-shutter: "\e925";
$tt-support: "\e926";
$tt-user: "\e927";
$tt-arrow: "\e928";
$tt-battery-empty: "\e929";

// $iconColorPrimary : rgb(64, 83, 100) !default;
// $iconColorSecondary : rgb(62, 221, 157) !default;
// $iconColorPaths : rgb(255, 255, 255) !default;

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'teslatools-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: $iconColorPaths;
}

.tt .path1 {
  &:before {
    content: $tt-path1;  
  }
}
.tt .path2 {
  &:before {
    content: $tt-path2;  
    margin-left: -1.291015625em;  
    color: $iconColorSecondary;
  }
}
.tt .path3 {
  &:before {
    content: $tt-path3;  
    margin-left: -1.291015625em;  
  }
}
.tt2 {
  &:before {
    content: $tt2;
  }
}
.tt-add-goods .path1 {
  &:before {
    content: $tt-add-goods-path1;  
  }
}
.tt-add-goods .path2 {
  &:before {
    content: $tt-add-goods-path2;  
    margin-left: -0.9755859375em;  
    color: $iconColorSecondary;
  }
}
.tt-bold-text-option {
  &:before {
    content: $tt-bold-text-option;
  }
}
.tt-coverage-level .path1 {
  &:before {
    content: $tt-coverage-level-path1;  
  }
}
.tt-coverage-level .path2 {
  &:before {
    content: $tt-coverage-level-path2;  
    margin-left: -1.08984375em;  
  }
}
.tt-coverage-level .path3 {
  &:before {
    content: $tt-coverage-level-path3;  
    margin-left: -1.08984375em;  
  }
}
.tt-coverage-level .path4 {
  &:before {
    content: $tt-coverage-level-path4;  
    margin-left: -1.08984375em;  
    color: $iconColorSecondary;
  }
}
.tt-diskette .path1 {
  &:before {
    content: $tt-diskette-path1;  
    color: $iconColorPrimary;
  }
}
.tt-diskette .path2 {
  &:before {
    content: $tt-diskette-path2;  
    margin-left: -0.9501953125em;  
    color: $iconColorPrimary;
  }
}
.tt-diskette .path3 {
  &:before {
    content: $tt-diskette-path3;  
    margin-left: -0.9501953125em;  
    color: $iconColorSecondary;
  }
}
.tt-download {
  &:before {
    content: $tt-download;
  }
}
.tt-download-button {
  &:before {
    content: $tt-download-button;
  }
}
.tt-drag .path1 {
  &:before {
    content: $tt-drag-path1;  
    color: $iconColorSecondary;  
    opacity: 0.2;
  }
}
.tt-drag .path2 {
  &:before {
    content: $tt-drag-path2;  
    margin-left: -1.146484375em;  
    color: $iconColorPrimary;  
    opacity: 0.2;
  }
}
.tt-envelope2 {
  &:before {
    content: $tt-envelope2;
  }
}
.tt-error {
  &:before {
    content: $tt-error;
  }
}
.tt-filter-filled-tool-symbol {
  &:before {
    content: $tt-filter-filled-tool-symbol;
  }
}
.tt-four-expand-arrows {
  &:before {
    content: $tt-four-expand-arrows;
  }
}
.tt-Group-30 .path1 {
  &:before {
    content: $tt-Group-30-path1;  
  }
}
.tt-Group-30 .path2 {
  &:before {
    content: $tt-Group-30-path2;  
    margin-left: -1em;  
  }
}
.tt-Group-30 .path3 {
  &:before {
    content: $tt-Group-30-path3;  
    margin-left: -1em;  
    color: $iconColorSecondary;
  }
}
.tt-Group-30 .path4 {
  &:before {
    content: $tt-Group-30-path4;  
    margin-left: -1em;  
  }
}
.tt-Group-149 .path1 {
  &:before {
    content: $tt-Group-149-path1;  
  }
}
.tt-Group-149 .path2 {
  &:before {
    content: $tt-Group-149-path2;  
    margin-left: -1.5283203125em;  
    color: $iconColorSecondary;
  }
}
.tt-Group-151 .path1 {
  &:before {
    content: $tt-Group-151-path1;  
  }
}
.tt-Group-151 .path2 {
  &:before {
    content: $tt-Group-151-path2;  
    margin-left: -1.5283203125em;  
    color: $iconColorSecondary;
  }
}
.tt-icon {
  &:before {
    content: $tt-icon;
  }
}
.tt-information-icon .path1 {
  &:before {
    content: $tt-information-icon-path1;  
  }
}
.tt-information-icon .path2 {
  &:before {
    content: $tt-information-icon-path2;  
    margin-left: -1em;  
    color: $iconColorSecondary;
  }
}
.tt-information-icon .path3 {
  &:before {
    content: $tt-information-icon-path3;  
    margin-left: -1em;  
    color: $iconColorSecondary;
  }
}
.tt-italicize-text {
  &:before {
    content: $tt-italicize-text;
  }
}
.tt-link-symbol {
  &:before {
    content: $tt-link-symbol;
  }
}
.tt-list2 {
  &:before {
    content: $tt-list2;
  }
}
.tt-menu {
  &:before {
    content: $tt-menu;
    color: $iconColorSecondary;
  }
}
.tt-menu-closed {
  &:before {
    content: $tt-menu-closed;
  }
}
.tt-menu-halfopen .path1 {
  &:before {
    content: $tt-menu-halfopen-path1;  
    color: $iconColorSecondary;
  }
}
.tt-menu-halfopen .path2 {
  &:before {
    content: $tt-menu-halfopen-path2;  
    margin-left: -1.169921875em;  
  }
}
.tt-menu-halfopen .path3 {
  &:before {
    content: $tt-menu-halfopen-path3;  
    margin-left: -1.169921875em;  
  }
}
.tt-excel .path1 {
  &:before {
    content: $tt-excel-path1;  
    color: $iconColorPrimary;
  }
}
.tt-excel .path2 {
  &:before {
    content: $tt-excel-path2;  
    margin-left: -1em;  
    color: $iconColorSecondary;
  }
}
.tt-more {
  &:before {
    content: $tt-more;
  }
}
.tt-newspaper .path1 {
  &:before {
    content: $tt-newspaper-path1;  
    color: $iconColorSecondary;
  }
}
.tt-newspaper .path2 {
  &:before {
    content: $tt-newspaper-path2;  
    margin-left: -1.001953125em;  
  }
}
.tt-notification {
  &:before {
    content: $tt-notification;
  }
}
.tt-numbered-list {
  &:before {
    content: $tt-numbered-list;
  }
}
.tt-package-for-delivery .path1 {
  &:before {
    content: $tt-package-for-delivery-path1;  
  }
}
.tt-package-for-delivery .path2 {
  &:before {
    content: $tt-package-for-delivery-path2;  
    margin-left: -1.490234375em;  
  }
}
.tt-package-for-delivery .path3 {
  &:before {
    content: $tt-package-for-delivery-path3;  
    margin-left: -1.490234375em;  
    color: $iconColorSecondary;
  }
}
.tt-package-for-delivery .path4 {
  &:before {
    content: $tt-package-for-delivery-path4;  
    margin-left: -1.490234375em;  
    color: $iconColorSecondary;
  }
}
.tt-Path-110 {
  &:before {
    content: $tt-Path-110;
  }
}
.tt-Path-137 {
  &:before {
    content: $tt-Path-137;
  }
}
.tt-Path-220 {
  &:before {
    content: $tt-Path-220;
  }
}
.tt-Path-221 {
  &:before {
    content: $tt-Path-221;
  }
}
.tt-printer .path1 {
  &:before {
    content: $tt-printer-path1;  
    color: $iconColorPrimary;
  }
}
.tt-printer .path2 {
  &:before {
    content: $tt-printer-path2;  
    margin-left: -1.06640625em;  
    color: $iconColorSecondary;
  }
}
.tt-printer .path3 {
  &:before {
    content: $tt-printer-path3;  
    margin-left: -1.06640625em;  
    color: $iconColorPrimary;
  }
}
.tt-right-arrow {
  &:before {
    content: $tt-right-arrow;
  }
}
.tt-right-quotation-mark {
  &:before {
    content: $tt-right-quotation-mark;
  }
}
.tt-search {
  &:before {
    content: $tt-search;
  }
}
.tt-security-atenttion {
  &:before {
    content: $tt-security-atenttion;
  }
}
.tt-security-atenttion-1 {
  &:before {
    content: $tt-security-atenttion-1;
  }
}
.tt-security-info {
  &:before {
    content: $tt-security-info;
  }
}
.tt-security-info-1 {
  &:before {
    content: $tt-security-info-1;
  }
}
.tt-security-off {
  &:before {
    content: $tt-security-off;
  }
}
.tt-security-off-1 {
  &:before {
    content: $tt-security-off-1;
  }
}
.tt-security-on {
  &:before {
    content: $tt-security-on;
    color: $iconColorSecondary;
  }
}
.tt-security-on-1 {
  &:before {
    content: $tt-security-on-1;
  }
}
.tt-settings-work-tool {
  &:before {
    content: $tt-settings-work-tool;
  }
}
.tt-table-grid {
  &:before {
    content: $tt-table-grid;
  }
}
.tt-text-height-filled {
  &:before {
    content: $tt-text-height-filled;
  }
}
.tt-tick {
  &:before {
    content: $tt-tick;
  }
}
.tt-two-columns-layout {
  &:before {
    content: $tt-two-columns-layout;
  }
}
.tt-warehouse-with-boxes .path1 {
  &:before {
    content: $tt-warehouse-with-boxes-path1;  
  }
}
.tt-warehouse-with-boxes .path2 {
  &:before {
    content: $tt-warehouse-with-boxes-path2;  
    margin-left: -1.095703125em;  
  }
}
.tt-warehouse-with-boxes .path3 {
  &:before {
    content: $tt-warehouse-with-boxes-path3;  
    margin-left: -1.095703125em;  
    color: $iconColorSecondary;
  }
}
.tt-warehouse-with-boxes .path4 {
  &:before {
    content: $tt-warehouse-with-boxes-path4;  
    margin-left: -1.095703125em;  
  }
}
.tt-warehouse-with-boxes .path5 {
  &:before {
    content: $tt-warehouse-with-boxes-path5;  
    margin-left: -1.095703125em;  
  }
}
.tt-warehouse-with-boxes .path6 {
  &:before {
    content: $tt-warehouse-with-boxes-path6;  
    margin-left: -1.095703125em;  
  }
}
.tt-warehouse-with-boxes .path7 {
  &:before {
    content: $tt-warehouse-with-boxes-path7;  
    margin-left: -1.095703125em;  
    color: $iconColorSecondary;
  }
}
.tt-bird {
  &:before {
    content: $tt-bird;
  }
}
.tt-bubble-question {
  &:before {
    content: $tt-bubble-question;
  }
}
.tt-calendar-empty {
  &:before {
    content: $tt-calendar-empty;
  }
}
.tt-camera-flip {
  &:before {
    content: $tt-camera-flip;
  }
}
.tt-chat2-01 {
  &:before {
    content: $tt-chat2-01;
  }
}
.tt-chevron-down {
  &:before {
    content: $tt-chevron-down;
  }
}
.tt-chevron-left {
  &:before {
    content: $tt-chevron-left;
  }
}
.tt-chevron-right-circle {
  &:before {
    content: $tt-chevron-right-circle;
  }
}
.tt-chevron-up {
  &:before {
    content: $tt-chevron-up;
  }
}
.tt-chip {
  &:before {
    content: $tt-chip;
  }
}
.tt-color-sampler {
  &:before {
    content: $tt-color-sampler;
  }
}
.tt-credit-card {
  &:before {
    content: $tt-credit-card;
  }
}
.tt-cube {
  &:before {
    content: $tt-cube;
  }
}
.tt-database {
  &:before {
    content: $tt-database;
  }
}
.tt-display {
  &:before {
    content: $tt-display;
  }
}
.tt-document2 {
  &:before {
    content: $tt-document2;
  }
}
.tt-envelope {
  &:before {
    content: $tt-envelope;
  }
}
.tt-file-zip {
  &:before {
    content: $tt-file-zip;
  }
}
.tt-fingerprint-simple-outline {
  &:before {
    content: $tt-fingerprint-simple-outline;
  }
}
.tt-Group-36 {
  &:before {
    content: $tt-Group-36;
  }
}
.tt-Group-37 {
  &:before {
    content: $tt-Group-37;
  }
}
.tt-list {
  &:before {
    content: $tt-list;
  }
}
.tt-lock {
  &:before {
    content: $tt-lock;
  }
}
.tt-map2 {
  &:before {
    content: $tt-map2;
  }
}
.tt-map-marker-user {
  &:before {
    content: $tt-map-marker-user;
  }
}
.tt-Montions {
  &:before {
    content: $tt-Montions;
  }
}
.tt-phone {
  &:before {
    content: $tt-phone;
  }
}
.tt-phoneq {
  &:before {
    content: $tt-phoneq;
  }
}
.tt-pin-person-012 {
  &:before {
    content: $tt-pin-person-012;
  }
}
.tt-play {
  &:before {
    content: $tt-play;
  }
}
.tt-radio-button {
  &:before {
    content: $tt-radio-button;
  }
}
.tt-screen-field {
  &:before {
    content: $tt-screen-field;
  }
}
.tt-search-1 {
  &:before {
    content: $tt-search-1;
  }
}
.tt-send-schat-01 {
  &:before {
    content: $tt-send-schat-01;
  }
}
.tt-share-01 {
  &:before {
    content: $tt-share-01;
  }
}
.tt-share3 {
  &:before {
    content: $tt-share3;
  }
}
.tt-shield {
  &:before {
    content: $tt-shield;
  }
}
.tt-shutter {
  &:before {
    content: $tt-shutter;
  }
}
.tt-support {
  &:before {
    content: $tt-support;
  }
}
.tt-user {
  &:before {
    content: $tt-user;
  }
}
.tt-arrow {
  &:before {
    content: $tt-arrow;
  }
}
.tt-battery-empty {
  &:before {
    content: $tt-battery-empty;
  }
}

