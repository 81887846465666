.aside-wrapper {
  z-index: 8;
  position: fixed;
  top: $header-height;
  right: 0;
  bottom: 0;
  width: 350px;
  background-color: $aside-bg-color;
  background: $aside-bg-image;
  color: $text-color;
  box-shadow: $aside-shadow;
  transform-origin: right;
  // padding: 15px;
  overflow-y: auto;

  .user-aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 15px;
    

    .ua-info {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
    .ua-avatar img {
      width: 70px;
      max-height: 70px;
      border-radius: 50%;
      margin-right: 15px;
    }
    .ua-details {
      h4 {
        font-size: 18px;
        font-weight: 600;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.2;
      }
    }
    .btn-big {
      margin: 0 20%;
    }
  }


  .settings-aside {
    padding: 15px;

    .sa-header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 8px;

      > div {
        flex: 0 1 100%;
        font-size: 16px;
        padding-bottom: 8px;
        font-weight: normal;
        text-align: center;
        cursor: pointer;
        position: relative;
        transition: .15s all linear;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 2px solid $text-color;
          transform-origin: center;
          transition: .15s all linear;
          transform: scaleX(0);
        }
        &.active {
          font-weight: 600;
          &::after {
            transform: scaleX(1);
          }
        }
      }
    }

    .sa-content {
      padding: 15px 0;
    }

  }

  
  .notifications-aside {
    
    .content-header {
      padding: 10px 15px 0;
      position: sticky;
      top: 0;
      background: $aside-bg-color;
    }
    .na-item-list {
      
      .na-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        padding: 10px;
        
        &.new-notification {
          background-color: rgba($light-gray, .5);
        }

        .na-thumb {

          img {
            width: 50px;
            max-height: 50px;
            border: 1px solid rgba($light-gray, .75);
            margin-right: 15px;
          }
        }
        .na-details {

          h4 {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 1.2;
          }
        }
      }
    }

  }




  &.filter-wrapper {
    top: $header-height + 40px; // header + subheader
  }
}


@media (max-width: 575px){
  .aside-wrapper {
    width: 100%;
  }
}