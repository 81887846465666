$teslatools-font-path: "./assets/fonts" !default;

@font-face {
  font-family: 'teslatools-icons';
  src:  url('#{$teslatools-font-path}/teslatools-icons.eot?ndfjsw');
  src:  url('#{$teslatools-font-path}/teslatools-icons.eot?ndfjsw#iefix') format('embedded-opentype'),
    url('#{$teslatools-font-path}/teslatools-icons.ttf?ndfjsw') format('truetype'),
    url('#{$teslatools-font-path}/teslatools-icons.woff?ndfjsw') format('woff'),
    url('#{$teslatools-font-path}/teslatools-icons.svg?ndfjsw#teslatools-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
