
/****************************************************************************/
// PRIMENG DATAVIEW CLASSES
// .ui-dataview
// .ui-dataview-header
// .ui-dataview-footer
// .ui-dataview-content
// .ui-dataview-layout-options

// MAIN DATAVIEW DESIGN
.ui-dataview {
  height: 100%;
  position: relative;

  .ui-dataview-header {
    background: transparent; // $content-header-bg-color;
    padding: 0;
    border: 0;

  }
  .ui-dataview-footer {
    position: fixed;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    color: $text-color;
    background: transparent; //$content-bg-color;
    padding: 0;
    border: 0;
    z-index: 5;
  }

  .ui-dataview-content {
    background-color: $content-bg-color;
    background: $content-bg;
    padding: 15px 10px 0;
    border: 0;

    &::-webkit-scrollbar-thumb {
      background-color: $custom-scrollbar-color;
      border-radius: 2px;
    }

    .icon.tt-more {
      font-size: 14px;
      // color: rgba($dark-gray, 0.75);
      color: rgba($text-color, 0.85);
    }
  }

  .ui-dataview-layout-options {
    min-width: 80px;

    .ui-button {
      height: 34px;
      &.ui-state-default {
        border-color: rgba($main-color, .5);
        background-color: $form-control-bg-color;
      }
      &.ui-state-active {
        background: $main-color;
        border-color: $main-color;
        &:focus {
          box-shadow: 0px 0px 5px $main-color;
        }
      }
    }
  }


  .ui-paginator {
    position: fixed;
    bottom: 0;
    left: 200px;
    right: 0;
    height: $footer-height;
    text-align: right;
    padding: 0 20px;
    background: $footer-bg-color; //#FFFFFF;
    border: 0 none;
    box-shadow: $shadowTop;
    transition: $layoutTransition;
    z-index: 1;

    .ui-paginator-page {
      border: none;
      height: $footer-height;
      padding: 0 10px;
      margin-top: -2px;
      line-height: $footer-height;
      border-radius: 0;
      color: $main-color;
      background: $footer-bg-color;
    }
    .ui-paginator-first,
    .ui-paginator-prev,
    .ui-paginator-next,
    .ui-paginator-last {
      border: none;
      height: $footer-height;
      margin-top: -2px;
      font-size: 12px;
      line-height: calc(#{$footer-height} - 2px);
      padding: 0 5px;
      border-radius: 0;
      background: $footer-bg-color;
    }

    .ui-paginator-page.ui-state-active {
      background-color: $main-color;
      color: #fff;
    }
    a:focus {
      outline: 0;
    }
  }
}
// @media (max-width: 992px) {
//   .ui-dataview-layout-options {
//     display: none;
//   }
// }
@media(max-width: 575px){
  .ui-dataview {
    .ui-dataview-footer {
      display: none !important;
    }
  }
}


/**************************************/
// CLIENT DATAVIEW DESIGN
.page-dataView {

  .ui-dataview-content {
    height: calc(100% - #{$content-header-height}); // should subtract each if exist: eg. 100% - header - footer - margin
    overflow-y: auto;

    .list-item {
      position: relative;
      margin: 0 10px 15px;
      box-shadow: $shadowMain;
      border-left-width: 15px;
      border-left-style: solid;
      border-color: $secondary-color;//$light-gray;
      border-radius: 4px;
      background: $content-bg-color;

      > div {
        height: 100%;
        padding: 10px;
      }
      h2 {
        color: $text-color;
        font-size: 1.4em;
        font-weight: normal;
      }
      span.light-bg {
        // font-size: 14px;
        line-height: 2;
        padding: 0 10px;
        color: $text-color;
        background: rgba($text-color, .05);
      }
      label {
        margin-bottom: 0;
        font-size: 12px;
        color: $dark-gray;
        line-height: 1.2;
      }
      .btn-icon {
        height: 30px;
        min-width: 30px !important;
      }
    }


    .grid-item {
      margin-bottom: 15px;
      padding-bottom: 10px;
      box-shadow: $shadowMain;
      border-top-width: 15px;
      border-top-style: solid;
      border-color: $secondary-color;//$light-gray;
      border-radius: 4px;
      background: $content-bg-color;

      > div {
        padding: 10px 10px 0;
      }
      h2 {
        color: $text-color;
        font-size: 1.4em;
        font-weight: normal;
      }
      span.light-bg {
        // font-size: 14px;
        line-height: 2;
        padding: 0 10px;
        color: $text-color;
        background: rgba($text-color, .05);
      }
      label {
        margin-bottom: 0;
        font-size: 12px;
        color: $dark-gray;
        line-height: 1.2;
      }
      .btn-icon {
        height: 30px;
        min-width: 30px !important;
      }
    }
  }

  @media (max-width: 575px){
    .ui-dataview-content {
      height: calc(100% - (#{$content-header-height} + 30px));
      .list-item {
        border-left-width: 0;
        border-top-width: 15px;
        border-top-style: solid;
        > div {
          height: auto;
          &:first-of-type {
            margin-right: 100px;
            width: calc(100% - 100px);
          }
          &:last-of-type {
            position: absolute;
            top: 0;
            width: auto;
            right: 0;
          }
        }
      }
    }

    .ui-dataview-content .list-item {
      .fx-bw-row:not(._ignore-device) {
        flex-direction: column;
      }
      ._xs-padding {
        padding: 10px 10px 0;
      }
    }
  }
}


/**************************************/
// PROPERTY DATAVIEW DESIGN
.property-dataView {
  .ui-dataview-header {
    margin-top: 12px;

    .content-header {
      background-color: transparent;
    }
    .content-title {
      .btn {
        line-height: 1;
        height: 28px;
        width: auto;
        min-width: 85px;
        font-size: 14px;
        margin: 0 2px;
        border: 0;
        transition: .1s all linear;

        &:active {
          box-shadow: none;
        }
      }
    }
  }

  .ui-dataview-content {
    height: calc(100% - 50px - #{$footer-height} - 20px); // should subtract each if exist: eg. 100% - header - footer - margin/padding
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      background-color: $custom-scrollbar-color;
      border-radius: 2px;
    }

    .list-item {
      position: relative;
      margin: 0 10px 15px;
      box-shadow: $shadowMain;
      border-left-width: 15px;
      border-left-style: solid;
      border-color: $light-gray;
      border-radius: 4px;
      background: $content-bg-color;

      > div {
        padding: 10px 0;
      }
      .block-height {
        position: relative;
        height: 100%;
        > div {
          height: inherit;
        }
        &:first-of-type::after {
          content: "";
          position: absolute;
          top: 10px;
          bottom: 10px;
          width: 2px;
          right: 2px;
          background: $light-gray;
        }
      }
      .property-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
        min-height: 150px;
        border-radius: 4px;
        box-shadow: $shadowBottom;
        cursor: pointer;
      }
      .item-stats {
        padding: 5px 0;
        font-size: 1.4rem;
        color: $text-color; //$dark-gray;

        > div { display: inline-block; }
        .stats {
          text-align: center;
          min-width: 40px;
          color: #fff;
          margin-right: 8px;
        }
        .stats-alert {
          background-color: $error-color;
        }
        .stats-primary {
          background-color: $main-color;
        }
      }
      h2 {
        color: $text-color;
        font-size: 1.4em;
        font-weight: normal;
      }
      span.light-bg {
        // font-size: 14px;
        line-height: 2;
        padding: 0 10px;
        color: $text-color;
        background: rgba($text-color, .05);
      }
      label {
        margin-bottom: 0;
        font-size: 12px;
        color: $dark-gray;
        line-height: 1.2;
      }
      strong {
        font-size: 14px;
        color: $text-color; //$dark-gray;
        font-weight: normal;
      }
      .btn-icon {
        height: 30px;
        min-width: 30px !important;
      }
    }


    .grid-item {
      margin-bottom: 15px;
      padding-bottom: 10px;
      box-shadow: $shadowMain;
      border-top-width: 15px;
      border-top-style: solid;
      border-color: $light-gray;
      border-radius: 4px;
      background: $content-bg-color;

      > div {
        padding: 10px 10px 0;
      }
      .property-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
        min-height: 150px;
        // border-radius: 4px;
        box-shadow: $shadowBottom;
        cursor: pointer;
      }
      .item-stats {
        font-size: 1.4rem;
        color: $text-color; //$dark-gray;

        > div { display: inline-block; }
        .stats {
          text-align: center;
          min-width: 40px;
          color: #fff;
          margin-right: 8px;
        }
        .stats-alert {
          background-color: $error-color;
        }
        .stats-primary {
          background-color: $main-color;
        }
      }
      h2 {
        color: $text-color;
        font-size: 1.4em;
        font-weight: normal;
      }
      span.light-bg {
        // font-size: 14px;
        line-height: 2;
        padding: 0 10px;
        color: $text-color;
        background: rgba($text-color, .05);
      }
      label {
        margin-bottom: 0;
        font-size: 12px;
        color: $dark-gray;
        line-height: 1.2;
      }
      strong {
        font-size: 14px;
        color: $text-color; //$dark-gray;
        font-weight: normal;
      }
      .btn-icon {
        height: 30px;
        min-width: 30px !important;
      }
    }
  }


  .ui-paginator {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0;
    border: 0;
    box-shadow: none;
    background: transparent;

    .ui-paginator-page {
      background: transparent;
    }
    .ui-paginator-first,
    .ui-paginator-prev,
    .ui-paginator-next,
    .ui-paginator-last {
      background: transparent;
    }
  }


  @media (max-width: 992px){
    .content-title {
      margin-bottom: 5px;
      .btn { min-width: auto !important; }
    }
    .ui-dataview-content .list-item {
      .block-height {
        height: auto;
        &:first-of-type::after {
          content: none;
        }
      }
      .button-group {
        position: absolute;
        right: 0;
        bottom: 10px;
        align-items: flex-end;
      }
    }
  }

  @media (max-width: 575px){
    .ui-dataview-content {
      height: 100%;
      overflow: hidden;
      padding-bottom: 15px;

      .list-item {
        border-left-width: 0;
        border-top-width: 15px;
        border-top-style: solid;
      }
    }

    .ui-paginator {
      position: fixed;
      box-shadow: $shadowTop;
    }

  }
}
