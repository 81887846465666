.app-master-wrapper {
  overflow: hidden;

  .app-header-wrapper {
    position: sticky;
    z-index: 20;
    user-select: none;
  }
  .app-main-wrapper {
    z-index: 19;
    height: calc(100vh - #{$header-height});

    .app-navbar-wrapper {
      position: fixed;
      top: $header-height;
      bottom: 0;
      left: 0;
      padding: 0;
      width: $navbar-width;
      z-index: 18;    
      background-color: $navbar-primary-bg-color;
      background-image: $navbar-bg-image;
      background-position: center right;
      background-size: cover;
      background-blend-mode: $navbar-bg-blend-mode;
      box-shadow: $navbar-shadow;
      user-select: none;
      will-change: contents;
    }
    .app-section-wrapper {
      height: 100%;
      padding-left: $navbar-width;
      background: $section-bg-color;
      background-image: $section-bg-image;
      background-position: center right;
      background-size: 100% auto;
      z-index: 17;
      will-change: contents;
    }
  }

}

// body
.navbar-closed {
  .app-footer-wrapper {
    left: $navbar-closed-width;
  }
  .menu-icon::before {
    color: $navbar-color;
  }
}

@media (max-width: 992px){
  $navbar-closed-width: 0px;

  .app-footer-wrapper {
    left: 0px !important;
  }

  .navbar-open {
    .app-section-wrapper {
      filter: blur(1px);
      opacity: .5;
    }
  }
}

