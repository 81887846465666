/*
 =========================================================
 * CUSTOM TABS DESIGN
 =========================================================
 */

.content-tabs {
    overflow: hidden;
}

.content-tabs-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
    height: 100%;
    border-top-width: 0;
}

.tabs {
    flex: 0 0 180px;
    background-color: $tabs-left-panel-bg-color;
    color: $text-color;
    margin-right: 8px;

    .tab {
        cursor: pointer;
        user-select: none;
        position: relative;
        &.active {
            border-left: 3px solid $tab-active-color;
            background-color: $tab-active-color;
            a {
                color: #FFFFFF;
            }
            .sub-tab {
              display: block;
            }
        }
        &.active::after {
            content: '';
            position: absolute;
            right: -6px;
            top: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 6px solid $tab-active-color;
        }
        a {
            padding: 8px 15px;
            display: block;
            text-decoration: none;
        }
        .sub-tab {
          background: #E0E2E4;
          display: none;
          a {
            color: $main-color;
            position: relative;
            padding-left: 40px;
            font-size: 1.4rem;
            line-height: 1;
            &::before {
              content: "";
              position: absolute;
              left: 24px;
              top: 50%;
              transform: translate(-50%, -50%);
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background-color: $main-color;
              transition: .1s all linear;
            }
          }
          
          a.active { font-weight: 600; }
          a.active::before {
            height: 10px;
            width: 10px;
            background-color: $secondary-color;
          }
        }
    }
}

.tab-section {
    flex: 1 1 100%;
    min-width: 0;
    height: inherit;
    overflow-y: auto;
    padding-top: 10px; // 5px;
    padding-right: 13px;
    padding-bottom: 10px; // 5px;
    padding-left: 5px;

    &::-webkit-scrollbar {
      width: 4px;
    }
}

.tab-options {
    background-color: $tabs-right-panel-bg-color;
    flex: 0 0 180px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    button {
        width: 100%;
    }
}
@media (max-width: 992px) {
  .content-tabs {
    .tabs { flex: 0 0 120px; }
  }
}

@media(max-width: 575px) {
    .content-tabs {
        overflow-y: auto;
        .content-inner {
            flex-direction: column;
            height: auto;
        }
        .tabs {
            flex: 0 1 auto;
            margin-right: 0;
        }
        .tab.active::after,
        .tab.active::before {
            display: none;
        }
        .tab-options {
            flex: 0 1 auto;
        }
    }
}