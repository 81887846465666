@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

$default_loader: #787878;
$error_loader: #F35B5B;
$success_loader: #3EDD9D;
$warn_loader: #F9C841;
$info_loader: #edeff0;
._loadingDirective {
    height: inherit;
    &._loadingDirectiveShow {
        overflow: hidden !important;
        >*:not(._loadingDirectiveLoaderDiv) {
            -webkit-filter: blur(5px);
            -moz-filter: blur(5px);
            -o-filter: blur(5px);
            -ms-filter: blur(5px);
            filter: blur(5px);
            pointer-events: none;
            user-select: none;
        }
        ._loadingDirectiveLoaderDiv {
            display: block !important;
        }
    }
    ._loadingDirectiveLoaderDiv {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 15em;
        height: auto;
        max-width: 100%;
        display: none;
        ._loadingDirectiveLoader {
            width: 100%;
            height: 100%;
            position: relative;
            margin-bottom: 1.5em;
            display: none;
            &::before {
                content: '';
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 30px;
                height: 30px;
                margin-top: -15px;
                margin-left: -15px;
                border-radius: 50%;
                border: 3px solid #E4E4E4;
                border-top-color: #405364;
                animation: spinner .6s linear infinite;
            }
        }
        ._loadingDirectiveLoaderText {
            width: 100%;
            text-align: center;
            color: $default_loader;
            font-weight: bold;
        }
    }
    &._loadingDirectiveShowLoader {
        ._loadingDirectiveLoaderDiv ._loadingDirectiveLoader {
            display: block !important;
        }
    }
    &.default {
        ._loadingDirectiveLoaderText {
            color: $default_loader;
        }
    }
    &.success {
        ._loadingDirectiveLoaderText {
            color: $success_loader;
        }
    }
    &.error {
        ._loadingDirectiveLoaderText {
            color: $error_loader;
        }
    }
    &.warn {
        ._loadingDirectiveLoaderText {
            color: $warn_loader;
        }
    }
    &.info {
        ._loadingDirectiveLoaderText {
            color: $info_loader;
        }
    }
}