.action-in-process {
  position: relative;

  .process-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: wait !important; // progress;

    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: repeating-linear-gradient( to right, 
        transparent 0%, 
        rgba(33, 37, 41, 0.75) 100%);
      background-size: 100px 100px;
  
      -webkit-animation: move .75s linear infinite; /* Safari 4+ */
      -moz-animation: move .75s linear infinite; /* Fx 5+ */
      -o-animation: move .75s linear infinite; /* Opera 12+ */
      animation: move .75s linear infinite; /* IE 10+, Fx 29+ */
    }
  }

}

@-webkit-keyframes move {
  0% { background-position: 0 0; }
  100% { background-position: 100px 0; }
}
@-moz-keyframes move {
  0% { background-position: 0 0; }
  100% { background-position: 100px 0; }
}
@-o-keyframes move {
  0% { background-position: 0 0; }
  100% { background-position: 100px 0; }
}
@keyframes move {
  0% { background-position: 0 0; }
  100% { background-position: 100px 0; }
}