
/****************************************************************************/
// PRIMENG DATATABLE CLASSES
// ui-table
// ui-datatable-caption
// ui-table-summary
// ui-sortable-column
// ui-table-scrollable-header
// ui-table-scrollable-body
// ui-table-scrollable-footer
// ui-table-responsive
// ui-table-loading
// ui-table-loading-content
// ui-table-wrapper
// ui-table-scrollable-wrapper
// ui-table-resizer-helper
// ui-table-reorder-indicator-top
// ui-table-reorder-indicator-top

// VARIABLES
$headPad: 10px !default; // table header padding top

// MAIN DATAVIEW DESIGN
.ui-table {
  height: 100%;

  .ui-table-caption {
    background: transparent;
    padding: 0;
    border: 0;
  }

  .ui-control {
    display: inline-block;
    margin: 0;
  }

  // .ui-table-wrapper, 
  .ui-table-scrollable-wrapper {
    height: inherit;
    > div { height: inherit };

    .ui-widget-header {
      border: 0;
      background: $content-header-bg-color;
      margin-top: $headPad;
      padding: 0 $headPad;
    }

    .ui-table-scrollable-header {
      th {
        height: calc(#{$content-header-height} - #{$headPad});
        background-color: $content-header-bg-color;
        color: $text-color;
        font-size: 12px;
        border: 0;
        text-transform: capitalize;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .fa {
          font-size: 16px;
          opacity: .75;
        }

        &.ui-sortable-column:not(.ui-state-highlight):hover {
          background-color: rgba(#EEEEEE, .75);
          color: $text-color;
        }
        &.ui-state-highlight {
          background-color: rgba($text-color, .25);
        }
      }
    }

    .ui-table-scrollable-body {
      padding: $headPad;
      height: calc(100% - (#{$content-header-height} * 2)); // content-inner - content-header - thead(content-header-height)

      .ui-table-tbody {
        tr {
          background: $content-bg-color;
          color: $text-color;
          border: 1px solid rgba(213, 213, 213, .75);
          td {
            font-size: 14px;
            padding: 4px 8px;
            height: 40px;
            border: none;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .btn {
            height: 28px;
            min-width: 28px;
            margin-right: 5px;
            padding: 0;
            line-height: 1;
          }

          &.ui-state-highlight {
            background-color: $main-color;
            color: #fff;
            
            .btn {
              color: #fff;
            }
          }
        }
      }


      &::-webkit-scrollbar-thumb {
        background-color: $custom-scrollbar-color;
        border-radius: 2px;
      }
    }
    
  }
  
  
  &.has-paginator {
    .ui-table-scrollable-wrapper {
      .ui-table-scrollable-body {
       height: calc(100% - (#{$content-header-height} * 2) - #{$footer-height}); // content-inner - content-header - thead(content-header-height) - paginator height ($footer-height)
      }
    }
  }

  &.multiple-lines {
    .ui-table-scrollable-wrapper {
      .ui-table-scrollable-body {
        .ui-table-tbody tr  td {
          white-space: normal;
        }
      }
    }
  }


  .ui-paginator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $footer-height;
    text-align: right;
    padding: 0 20px; 
    background: $footer-bg-color;
    border: 0 none;
    box-shadow: $shadowTop;
    transition: $layoutTransition;
    z-index: 1;

    .ui-paginator-page {
      border: none;
      height: $footer-height;
      padding: 0 10px;
      margin-top: -2px;
      line-height: $footer-height;
      border-radius: 0;
      color: $main-color;
      background: $footer-bg-color;
    }
    .ui-paginator-first,
    .ui-paginator-prev,
    .ui-paginator-next,
    .ui-paginator-last {
      border: none;
      height: $footer-height;
      margin-top: -2px;
      font-size: 12px;
      line-height: 38px;
      padding: 0 5px;
      border-radius: 0;
      background: $footer-bg-color;
    }

    .ui-paginator-page.ui-state-active {
      background-color: $main-color;
      box-shadow: $shadowTop;
      color: #fff;
    }
    a:focus {
      outline: 0;
    }

    // templateLeft, templateRight
    .ui-paginator-left-content,
    .ui-paginator-right-content {
      font-size: 12px;
      color: $text-color;
      line-height: $footer-height;
      margin: 0;
    }
    // rows per page dropdown
    .ui-dropdown {
      width: auto !important;
      line-height: 1;
      height: 26px;
      margin-bottom: 2px;
      .ui-dropdown-trigger span{
        margin-top: 2px;
      }
    }
  }
}

@media (max-width: 992px) {
  .ui-table {
    .ui-table-scrollable-wrapper .ui-table-scrollable-body {
      height: calc(100% - ((#{$content-header-height} * 2) + 30px));
    }
  }
}

@media(max-width: 575px){
  .ui-table {
    table {
      table-layout: auto; // fixes width of td in each table element
    }
    .ui-table-scrollable-wrapper {
      .ui-widget-header {
        margin-top: 0;
      }
      .ui-table-scrollable-body {
        height: calc(100% - (#{$content-header-height} + 30px));
      }
    } 

    td {
      border-left-width: 0 !important;
      border-right-width: 0 !important;
    }
    .ui-paginator {
      text-align: center;
      .ui-paginator-left-content,
      .ui-paginator-right-content {
        display: none !important;
      }
    }
  }
}
