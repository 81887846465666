.app-subheader-wrapper { // subheader
  height: $subheader-height;
  min-height: $subheader-height;
  background-color: $subheader-bg-color;
  color: $subheader-color;
  position: sticky;
  // top: $header-height;
  z-index: 9;
  box-shadow: $subheader-shadow;
  // padding: 5px 0px;

  & + .app-subheader-wrapper {
    height: auto;
    // top: $header-height + $subheader-height;
    z-index: 8;

    & + .app-subheader-wrapper {
      z-index: 7;
    }
  }
}

.subheader-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 5px $page-spacing;

  .s-left, .s-right {
    display: flex;
    align-items: center;

    min-height: 30px;
  }

  .s-left {
    button {
      font-size: 14px;
      height: 28px;
      line-height: 1;
      // line-height: 30px;
      // height: 30px;
      // margin: 2.5px 0;
    }

    .btn-nav-back {
      font-size: 1.4rem;
      line-height: 1;
      height: 28px;
      min-width: auto;
      padding: 0 15px;
      border: none;
      font-weight: 600;
      background-color: transparent;

      .icon {
        margin-right: 3px;
        font-size: 1.2rem;
      }
    }
  }
  .s-right {

    button {
      height: 28px;
      margin-left: 8px;
      line-height: 1;
      .icon {
        color: $text-color;
      }
    }
    .btn-filter {
      padding: 0 5px !important;
      width: 28px;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.4; // 28px;
      margin-bottom: 0;
      user-select: none;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .active {
      position: relative;
      &::before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.1);
      }
      &::after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 7px solid $subheader-bg-color;
      }
      .icon {
        color: $secondary-color;
      }
    }
  }

}
