/*
 =========================================================
 * CUSTOM PRIME NG COMPONENT DESIGN
 =========================================================
 */

// FIX PRIME STYLE
.ui-g {
  // margin: 0 -10px; }
  margin: 0 #{calc($page-spacing / -2)};
}
[class*='ui-g-'],
[class*='ui-sm-'],
[class*='ui-md-'],
[class*='ui-lg-'],
[class*='ui-xl-'] {
  // padding: 0 10px; }
  padding: 0 #{calc($page-spacing / 2)};
}
[class*='ui-g-'].ui-g-nopad {
  padding: 0;
}

// INPUTS
.ui-control {
  $controlBottomPadding: 20px;
  margin-top: 1em; // 16px;
  position: relative;

  .ui-float-label {
    padding-bottom: $controlBottomPadding;
  }
  .ui-float-label > .ui-inputtext:focus ~ label,
  .ui-float-label > .ui-inputtext.ui-state-filled ~ label,
  .ui-float-label > .ui-inputwrapper-focus ~ label,
  .ui-float-label > .ui-inputwrapper-filled ~ label {
    left: 0;
  }
  label {
    margin-bottom: 0;
    user-select: none;
    // top: calc(50% - (#{$controlBottomPadding} / 2));
    top: 17px;
    left: 10px;
  }

  .ui-inputtext {
    width: 100%;
    color: $text-color;
    display: block;
    padding-left: 10px;
    background-color: $form-control-bg-color;
  }
  .ui-inputtext:not(.ui-dropdown-label) {
    padding-right: 10px;
  }

  .ui-state-default {
    border-color: rgba($main-color, 0.5);
  }
  .ui-inputtext:enabled:hover {
    border-color: rgba($main-color, 0.8);
  }
  .ui-inputtext.ui-state-focus,
  .ui-inputtext:focus {
    box-shadow: $shadowMain;
    border-color: rgba($main-color, 0.8);
  }

  .ui-corner-all {
    border-radius: 2px;
  }

  .ui-state-disabled,
  .ui-widget:disabled {
    background-color: #efefef;
    + label {
      opacity: 0.35;
    }
  }

  .ui-inputtext[readonly].ui-state-focus,
  .ui-inputtext[readonly].ui-inputtext:focus {
    box-shadow: none;
  }

  // spinner
  .ui-spinner {
    width: 100%;

    .ui-spinner-button {
      border: 1px solid rgba($main-color, 0.5) !important;
      background: rgba($main-color, 0.85) !important;
    }

    &:not(.ui-state-disabled) .ui-spinner-button:enabled:active {
      background: $main-color !important;
      color: $success-color;
    }
  }

  // hint, errors
  .ui-hint {
    position: absolute;
    bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #898989;
  }
  .ui-error {
    position: absolute;
    bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    color: $error-color;
  }
  .ui-float-label input.ng-dirty.ng-invalid ~ label {
    color: $error-color;
  }
  .ui-inputtext.ng-dirty.ng-invalid,
  p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
  p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
  p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext,
  p-chips.ng-dirty.ng-invalid > .ui-inputtext,
  p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
  p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
  p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
  p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
  p-listbox.ng-dirty.ng-invalid .ui-inputtext,
  p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
  p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
  p-selectbutton.ng-dirty.ng-invalid .ui-button,
  p-togglebutton.ng-dirty.ng-invalid .ui-button {
    border-color: $error-color;
  }
}

// INPUT ADDON
.ui-inputgroup .ui-inputgroup-addon {
  background-color: $form-control-bg-color;
  border-color: rgba($main-color, 0.5);
  color: $text-color;
}

// DROPDOWN
.ui-dropdown {
  width: 100% !important;
  background-color: $form-control-bg-color;
  transition: 0.2s;
  &.ui-state-focus,
  &:focus {
    box-shadow: $shadowBottom;
    border-color: rgba($main-color, 0.8);
  }

  &:not(.ui-state-disabled):hover {
    border-color: rgba($main-color, 0.8);
    background-color: $form-control-bg-color;
  }
  .ui-dropdown-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ui-dropdown-trigger {
    // border: none;
    // background-color: rgba(255, 255, 255, .75);
    background-color: $form-control-bg-color;
    .fa {
      font-size: 20px;
      margin-left: -0.325em;
      color: $text-color;
    }
  }
}
.ui-dropdown-panel {
  // panel must stay outside of ui-dropdown in case its attached to body
  max-width: 12em; //1px; // to prevent autowidth of panel based on inner content
  .ui-dropdown-filter-container {
    .ui-inputtext {
      padding-left: 0.5em;
      background: initial;
      color: initial;
    }
    .ui-inputtext:focus {
      box-shadow: $shadowMain;
      border-color: rgba($main-color, 0.8);
    }
  }
  .ui-dropdown-item {
    padding: 4px;
  }
  .ui-state-highlight {
    background: $main-color;
  }
}

// MULTISELECT
.ui-multiselect {
  width: 100% !important;
  // height: 34px;
  white-space: nowrap;
  background-color: $form-control-bg-color;
  color: $text-color;
  transition: 0.2s;

  &.ui-state-focus,
  &:focus {
    box-shadow: $shadowBottom;
    border-color: rgba($main-color, 0.8);
  }

  &:not(.ui-state-disabled):hover {
    border-color: rgba($main-color, 0.8);
    background-color: $form-control-bg-color;
    color: $text-color;
  }

  .ui-multiselect-label {
    background-color: $form-control-bg-color;
    padding-left: 10px;
    height: 32px;
  }

  .ui-multiselect-trigger {
    // border: none;
    // background-color: rgba(255, 255, 255, .75);
    background-color: $form-control-bg-color;
    .fa {
      margin-top: 0.3em;
      font-size: 20px;
      margin-left: -0.325em;
      color: $text-color;
    }
  }
}
.ui-multiselect-panel {
  .ui-multiselect-filter-container .fa,
  .ui-multiselect-close {
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
  }

  .ui-multiselect-filter-container {
    .ui-inputtext {
      padding-left: 1.25em;
      background: initial;
      color: initial;
    }
    .ui-inputtext:focus {
      box-shadow: $shadowMain;
      border-color: rgba($main-color, 0.8);
    }
  }

  .ui-multiselect-item {
    label {
      margin-bottom: 0;
    }
    .ui-chkbox {
      margin: 2px 10px 2px 0;
      .ui-chkbox-box {
        width: 20px;
        height: 20px;
        line-height: 20px;
        .ui-chkbox-icon {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
    &.ui-state-highlight {
      background: $main-color;
    }
  }
}

// AUTOCOMPLETE
.ui-autocomplete {
}
.ui-autocomplete-panel {
  .ui-autocomplete-item {
    padding: 4px;
  }
  .ui-state-highlight {
    background: $main-color;
  }
}

// DATEPICKER & TIMEPICKER
.ui-calendar {
  width: 100%;
}
.ui-datepicker {
  // .ui-state-highlight { // today
  //   background-color: $light-gray !important;
  // }
  .ui-state-active {
    // selected
    background-color: $main-color !important;
  }
}

// password strength indicator overlay
.ui-password-panel {
  border-color: $main-color !important;
  background: $main-color !important;
  color: #ffffff !important;

  .ui-password-info {
    font-size: 1.2rem;
  }
}

// BUTTONS
.btn {
  box-sizing: border-box;
  position: relative;
  user-select: none;
  cursor: pointer;

  display: inline-block;
  white-space: nowrap;
  vertical-align: bottom;
  min-width: 88px;
  height: 40px;
  line-height: 36px;
  font-size: 16px;

  text-align: center;
  margin: 0;
  padding: 0 15px;
  outline: 0;
  border-radius: 2px;
  border: none;
  background-color: $default-btn;
  color: $text-color;

  .icon {
    color: inherit;
  }
  .fa {
    color: inherit;
  }

  &:active {
    box-shadow: $shadowMain;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default !important;
  }
  // &:hover:not(:disabled) { background-color: $default-btn-active; }
}
[class*='btn']:not([class*='btn-']) {
  &:hover:not(:disabled) {
    background-color: $default-btn-active !important;
  }
}

.btn-primary {
  background-color: $primary-btn;
  color: #fff;
  &:hover:not(:disabled) {
    background-color: $primary-btn-active;
  }
  &.btn-icon .icon {
    color: $primary-btn;
  }
}
.btn-light {
  background-color: $light-btn;
  border: 1px solid rgba($main-color, 0.4);
  color: $primary-btn;
  &:hover:not(:disabled) {
    background-color: $light-btn-active;
  }
}
.btn-success {
  background-color: $success-btn;
  color: #fff;
  &:hover:not(:disabled) {
    background-color: $success-btn-active;
  }
}
.btn-error {
  background-color: $error-btn;
  color: #fff;
  &:hover:not(:disabled) {
    background-color: $error-btn-active;
  }
}
.btn-warn {
  background-color: $warn-btn;
  color: #fff;
  &:hover:not(:disabled) {
    background-color: $warn-btn-active;
  }
}
.btn-icon {
  background-color: transparent !important;
  min-width: auto !important;
  line-height: 1 !important;
  padding: 0 8px !important;
}
.btn-default {
  // background-color: $default-btn;
  border: 1px solid rgba($main-color, 0.4);
  &:hover:not(:disabled) {
    background-color: $default-btn-active;
  }
}
.btn-big {
  height: 60px;
  .icon {
    font-size: 22px;
    vertical-align: text-bottom;
  }
}
.btn-small {
  height: 30px;
  line-height: 1;
}

.ui-autocomplete {
  width: 100%;
}

.ui-chips {
  .ui-chips-token {
    background-color: $main-color;
  }
  > ul:not(.ui-state-disabled):hover {
    border-color: rgba($main-color, 0.8);
  }
}

// UI_BOX checkbox, radio button, switch button
.ui-box-label {
  position: absolute;
  top: -15px;
  font-size: 12px;
  line-height: 1;
  user-select: none;
  color: #898989;
}
.ui-box {
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  label {
    padding-right: 15px;
    user-select: none;
    color: $text-color;
  }

  &.color-white {
    label {
      color: white;
    }
  }
}

// CHECKBOX
.ui-chkbox {
  margin: 5px 10px 5px 0;

  .ui-chkbox-box {
    width: 24px;
    height: 24px;
    line-height: 24px;

    .ui-chkbox-icon {
      font-size: 20px;
      line-height: 22px;
    }
    &.ui-state-focus.ui-state-active {
      background: #fff;
    }
    &.ui-state-focus {
      box-shadow: $shadowBottom;
    }
    &.ui-state-active {
      background: #fff;
      color: $main-color;
    }
  }
}

// RADIO BUTTON
.ui-radiobutton {
  margin: 5px 10px 5px 0;
  .ui-radiobutton-box {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .ui-state-active {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    .ui-radiobutton-icon {
      height: 16px;
      width: 16px;
      background: $main-color;
      border-radius: 50%;
      margin: 0;

      &:before {
        content: none !important;
      }
    }
  }
}

// CHECKBOX, RADIO BUTTON COLOR VARIATIONS
.box-rounded .ui-chkbox-box {
  border-radius: 50% !important;
}
.box-success {
  .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
    background-color: $success-color !important;
    position: static;
  }
  .ui-chkbox-box.ui-state-active {
    color: $success-color !important;
  }
}
.box-error {
  .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
    background-color: $error-color !important;
  }
  .ui-chkbox-box.ui-state-active {
    color: $error-color !important;
  }
}
.box-warn {
  .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
    background-color: $warn-color !important;
  }
  .ui-chkbox-box.ui-state-active {
    color: $warn-color !important;
  }
}

p-inputswitch {
  height: 34px;

  .ui-inputswitch {
    height: 28px !important;
    margin: 3px 10px 3px 0;

    &.ui-inputswitch-checked {
      .ui-inputswitch-on {
        background: $main-color !important;
      }
    }

    .ui-inputswitch-on,
    .ui-inputswitch-off {
      span {
        line-height: 26px;
      }
    }
  }

  &:not([onlabel]),
  &:not([offlabel]) {
    .ui-inputswitch {
      border-radius: 34px !important;
      width: 54px !important;

      .ui-inputswitch-on,
      .ui-inputswitch-off {
        width: 100% !important;
        background: #fff !important;
        transition: all 0.1s ease !important;
        span {
          display: none !important;
        }
      }

      .ui-inputswitch-handle {
        width: 22px !important;
        height: 22px !important;
        border-radius: 50% !important;
        left: 2px !important;
        margin: 2px 0px !important;
        background: $main-color !important;
        transition: all 0.1s ease !important;
      }

      &.ui-inputswitch-checked {
        .ui-inputswitch-on {
          background: $main-color !important;
        }
        .ui-inputswitch-handle {
          left: 28px !important;
          background: #fff !important;
        }
      }

      .ui-state-focus {
        background: initial;
      }
    }
  }
}

p-chips {
  width: 100%;
  .ui-chips > ul.ui-inputtext {
    padding-left: 10px;
  }
}

.ui-dialog {
  border: 0;

  &.ui-widget {
    .ui-dialog-titlebar {
      padding: 5px 10px;
      background: $modal-header-bg-color;
      border-bottom: 1px solid $dark-gray;
      .ui-dialog-title {
        color: #fff;
        padding: 0 5px;
        margin: 0;
        user-select: none;
      }
      .ui-dialog-titlebar-icon {
        color: #fff;
        padding: 5px;
        &:hover {
          background: transparent;
        }
      }
      // .ui-dialog-titlebar-close { }
    }
    .ui-dialog-content {
      padding: 20px;
      background-color: $content-bg-color;
      background: $content-bg;
      color: $content-color;
      max-height: calc(100vh - 240px);
    }
    .ui-dialog-footer {
      padding: 5px 10px;
      background-color: $content-bg-color;
      color: $content-color;
      border: 0;
      border-top: 1px solid rgba($dark-gray, 0.5);

      button {
        height: 30px;
        min-width: 30px !important;
        line-height: 1;
      }
    }
  }

  &.tabs-modal {
    width: 75vw !important;
    .ui-dialog-content {
      padding: 0;

      .content {
        padding: 0;
      }
      .content-tabs {
        min-height: inherit;
        max-height: inherit;
      }
    }
  }
}
.ui-dialog-mask {
  background-color: #000;
  opacity: 0.5;
}

// Overlay panel
.ui-overlaypanel {
  .ui-overlaypanel-content {
    padding: 0;
  }
  .panel-option {
    display: flex;
    flex-direction: column;
    button {
      text-align: left;
      border-radius: 0 !important;
    }
  }
}

@media (max-width: 575px) {
  .ui-g {
    margin: 0;
  }
  [class*='ui-g-'],
  [class*='ui-sm-'],
  [class*='ui-md-'],
  [class*='ui-lg-'],
  [class*='ui-xl-'] {
    padding: 0;
  }

  .tabs-modal {
    width: 95vw !important;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }
}

/*
 =========================================================
 * CUSTOM PRIMENG DATATABLE DESIGN
 * CUSTOM PRIMENG DATAVIEW DESIGN
 =========================================================
 */
// @import '~assets/scss/module/moduleName';
@import './dataView'; // PRIMENG DATAVIEW DESIGN
@import './dataTable'; // PRIMENG DATATABLE DESIGN
