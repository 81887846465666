.c-header-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    height: $content-header-height;
    min-height: $content-header-height;
    background-color: $content-header-bg-color;
    padding: 0 5px;
    
    .c-header-title,
    .c-header-content,
    .c-header-options {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

        >:first-child {
            margin-left: 0px;
        }
        > * {
            margin-left: 8px;
            height: 34px;
        }

        > label {
            line-height: 34px;
            font-size: 14px;
            color: $dark-gray;
        }
        label {
            margin-bottom: 0;
        }

        h3 {
            color: $text-color;
            font-size: 1.7rem;
            font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
            font-weight: 400;
            text-transform: capitalize;
            line-height: 34px;
            margin-bottom: 0;
            padding: 0px 10px;
        }
    
        .ui-control {
            margin-top: 0;
            height: 34px;
        }
        .btn {
            font-size: 14px;
            line-height: 34px;
            min-width: 30px !important;

            .icon, .fa {
                color: $text-color;
            }
            .icon.tt-more {
                color: rgba($text-color, 0.85);
            }
        }
    }
}

  
@media (max-width: 992px) {
    .c-header-wrap {
        height: auto;
        flex-wrap: wrap;
        padding-bottom: 5px;
        overflow-x: auto;
        overflow-y: hidden;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
}