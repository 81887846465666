/*
 =========================================================
 * CUSTOM LOGIN/REGISTER PAGEs DESIGN
 =========================================================
 */

.login-form,
.forgot-password-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $content-bg-color;
  background: $content-bg;
  box-shadow: 0 3px 66px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 50px;
  min-width: 30vw;

  .form-header {
    .name {
      color: $text-color;
      text-align: center;
      font-size: 3em;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
    }
    .company-logo {
      width: 120px;
      height: 40px;
      opacity: 0.7;
      background-image: $content-company-logo-normal;
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin-bottom: 10px;
    }
  }
  .form-body {
    .nav-links {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 5px;
      a {
        text-align: center;
        flex: 1 1 auto;
        color: $text-color;
        font-size: 1.2rem;
        padding: 10px;
        text-decoration: none;
      }
      .active {
        border-bottom: 2px solid $text-color;
      }
    }
    .main {
      display: flex;
      flex-direction: column;

      .r-f-block {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
      }
      .forgot {
        color: #6d6d6d;
        font-weight: lighter;
        transition: 0.15s color ease-in;
        &:hover {
          color: $text-color;
        }
      }
    }
  }
  .form-footer {
    text-align: right;
    margin-top: 15px;
    .btn-default {
      width: 48%;
      &.left {
        float: left;
      }
    }
  }

  .section-description {
    font-size: 1.2em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    color: $text-color;
    width: 100%;
  }
  .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    border: 1px solid $light-gray;
    padding: 0.5em;
    cursor: pointer;
    &.active {
      background-color: $light-gray;
    }
    .item-image {
      order: 0;
      flex: 0 1 5em;
      align-self: auto;
      text-align: center;
      .item-image-container {
        height: 3.5em;
        width: 3.5em;
        border-radius: 50%;
        box-shadow: $shadowBottom;
        position: relative;
        background-color: white;
        i,
        span {
          font-size: 1.5em;
          color: rgba($dark-gray, 0.9);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .item-content {
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
      .item-main {
        font-weight: bold;
        color: $main-color;
        font-size: 1.2em;
      }
      .item-description {
        font-size: 1.2rem;
        color: $dark-gray;
      }
    }
    .item-icon {
      order: 0;
      flex: 0 1 2em;
      align-self: auto;
      text-align: center;
      position: relative;
      i,
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .scroll {
    max-height: 60vh;
    overflow-y: scroll;
  }

  .institutionDescription {
    width: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 1em;
    .item-image-container {
      height: 4.5em;
      width: 4.5em;
      border-radius: 50%;
      box-shadow: $shadowBottom;
      position: relative;
      margin: 0 auto;
      background-color: white;
      i,
      span {
        font-size: 2em;
        color: rgba($dark-gray, 0.9);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .description {
      padding: 0.5em;
      color: $dark-gray;
      font-size: 1.2em;
      font-weight: bold;
    }
  }
}

.register-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $content-bg-color;
  background: $content-bg;
  box-shadow: 0 3px 66px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 50px;
  min-width: 30vw;

  .form-header {
    .name {
      color: $text-color;
      text-align: center;
      font-size: 3em;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
    }
    .company-logo {
      width: 120px;
      height: 40px;
      opacity: 0.7;
      background-image: $content-company-logo-normal;
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin-bottom: 10px;
    }
  }
  .form-body {
    .nav-links {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 5px;
      a {
        text-align: center;
        flex: 1 1 auto;
        color: $text-color;
        font-size: 1.2rem;
        padding: 10px;
        text-decoration: none;
      }
      .active {
        border-bottom: 2px solid $text-color;
      }
    }
    .main {
      display: flex;
      flex-direction: column;

      .r-f-block {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
      }
      .forgot {
        color: #6d6d6d;
        font-weight: lighter;
        transition: 0.15s color ease-in;
        &:hover {
          color: $text-color;
        }
      }
    }
  }
  .form-footer {
    text-align: right;
    margin-top: 15px;
    .btn-default {
      width: 180px;
    }
  }
}

.forgot-password-form .form-footer .btn-default {
  width: 68%;
}
