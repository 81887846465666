/*
 =========================================================
 * CUSTOM PROPERTY PAGE DESIGN
 =========================================================
 */

app-property {

  .property-category {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    // min-height: 94px;
    flex-grow: 1;
    flex-shrink: 1;

    position: relative;
    margin-bottom: 15px;
    box-shadow: $shadowMain;
    // background: linear-gradient( to right, #fff, $light-gray ) $main-color;
    // background: url('../../../assets/img/property.png') center/100% auto $main-color;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: $main-color;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient( to right, #fff, transparent ) ;
      opacity: 1;
    }

    > div {
      padding: $page-spacing;
      z-index: 2;
      span {
        text-transform: capitalize;
      }
      strong {
        text-transform: uppercase;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .prop-icon {
      color: $main-color;
      font-size: 40px;
      line-height: 1;
      .icon::before {
        color: inherit;
      }
    }
    .prop-type {
      display: flex;
      flex-direction: column;
      color: $main-color;
      flex: 1 1 100%;
      overflow: hidden;
    }
    .prop-total {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 70px;
      min-width: 70px;
      text-align: center;
      color: #fff;
      background-color: $main-color;
      span {
        font-size: 14px;
      }
    }

    &.active {
      background-image: none !important;
      .prop-icon, .prop-type {
        color: #fff;
      }
      &::before {
        // opacity: 0;
        background-image: none;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }


  .property-nav-mobile {
    display: none;
    .subheader-wrapper {
        padding: 0 !important;
    }
    .s-left {
        width: 100% !important;
        white-space: nowrap !important;
        flex: none !important;
        display: block !important;
        overflow-x: scroll !important;
        text-align: center;
        div[subheader-left] {
            justify-content: space-between;
            display: flex;
            .property-category-mobile {
                text-transform: uppercase;
                display: inline-block; // min-width: 8em !important;
                flex: 1; // width: auto;
                padding: .5em 1em; // font-weight: bold;
                cursor: pointer;
                span {
                    font-weight: lighter;
                    font-size: .8em;
                    display: block;
                }
                strong {
                    font-weight: bold;
                }
                &.active {
                    color: white;
                    background-color: $main-color;
                }
            }
        }
    }
  }

  @media(max-width:1440px) {
    .property-nav-mobile {
        display: block !important;
    }
    .property-nav {
        display: none !important;
    }
    .content._fx75 {
        flex-basis: 100% !important;
    }
  }


}




app-property-info {

  .property-view {
    height: 100%;
    // overflow-y: auto;
    // margin-right: -10px;
    // padding-right: 10px;

    &::-webkit-scrollbar-thumb {
      background-color: $custom-scrollbar-color;
      border-radius: 2px;
    }
    .content-header {
      margin: 0;
    }
    .property-tabs-options {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      height: 70px;

      .property-tabs {
        // padding-bottom: 20px;
        // margin-top: 1em;
      }
      .btn {
        border-radius: 5px;
        &:active {
          box-shadow: none;
        }
      }
    }
    .property-tab-content {
      overflow-y: scroll;
      height: calc(100% - #{$content-header-height} - 70px); // content - content-header - tabs
      padding: 0 9px 0 15px;

      .property-image {
        border-radius: 4px;
        box-shadow: $shadowBottom;
      }

      .property-details {
        padding-top: 20px;
        color: $text-color;

        // .property-name { }

        .property-info > div { margin-right: 10px; }
        .property-address > div { margin-left: 10px; }

        .property-info, .property-address {
          h3 {
            border-bottom: 2px solid $light-gray;
            font-weight: 400;
            padding: 0 5px 5px;
            margin-bottom: 10px;
          }
          > div {
            margin-bottom: 5px;
            font-size: 13px;
            line-height: 1.8;
            align-items: center;

            span {
              flex: 1 1 50%;
              white-space: nowrap;
              padding: 0 10px;
            }
            strong {
              flex: 1 1 100%;
              // text-align: right;
              padding: 0 10px;
              line-height: 1.5;
            }

            &:nth-of-type(odd) {
              background: rgba($text-color, .05);
            }
          }
        }

        // .property-address { }
      }
    }


    @media(max-width: 992px) {
      .fx-bw-row:not(._ignore-media) {
        flex-direction: column;
      }
    }

  }
}



app-property-report-item {
  .property-reports-details {
    height: 100px;
    background-color: $secondary-color;
    padding: 5px 15px;
  }
  .property-reports-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    height: calc(100% - 100px); // - height of details block
    padding: 15px;
  }
}

app-property-report-item-block {
  .property-report {
    // flex: 0 0 30%;
    flex: 0 0 22.5%;
    min-width: 350px;
    max-width: 400px;
    padding: 5px;
    background-color: $content-bg-color;
    background: $content-bg;
    color: $content-color;
    height: 100%;
    margin-right: 15px;
    overflow: hidden;
    box-shadow: $shadowMain;

    .property-report-header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      padding: 0 5px;
      margin-bottom: 10px;

      h3 {
        flex-basis: 100%;
        padding-left: 10px;
        margin-right: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .btn-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25em;
      }
      .op-indicator {
        font-size: 12px;
        color: $text-color;
        // padding: 4px;
        padding-left: 10px;
        padding-right: 4px;
      }
    }
    .property-report-body {
      height: calc(100% - 60px);
      overflow-y: auto;
      padding: 0 5px;

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $custom-scrollbar-color;
        border-radius: 2px;
      }
    }

    .draggable-item {
      // padding: 8px;
      box-shadow: $shadowMain;
      margin-bottom: 15px;
      border: 1px solid transparent;
      background: rgba($text-color, .03);
      cursor: -webkit-grab;
      user-select: none;
      > div {
        padding-top: 8px;
        padding-right: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        &:first-of-type {
          padding-bottom: 2px;
        }
        &:last-of-type {
          padding-top: 2px;
        }
      }

      .draggable-index {
        align-self: baseline;
        min-width: 40px;
        // max-width: 60px;
        font-size: 14px;
        line-height: 1.8;
        text-align: center;
        border: 1px solid rgba($dark-gray, .5);
        margin-right: 10px;
      }
      .draggable-name {
        flex-basis: 100%;
        border-bottom: 1px solid rgba($dark-gray, .5);
      }

      .btn-icon {
        height: 30px;
      }
      .fa {
        line-height: 30px;
        color: $text-color;
      }

      &.active {
        // box-shadow: inset 0 0 0px 1px $success-color, inset 5px 0px 0px 0px $success-color;
        // border: 1px solid $success-color;
        border-style: solid;
        border-color: $secondary-color;
        border-left-width: 5px;
      }
      &.dragging {
        opacity: .3;
        background-color: rgba(255, 255, 227, 0.2);
      }
      &.over {
        border: 1px dashed $dark-gray !important;
        // border-color: $dark-gray;
        background-color: rgba($light-gray, .4);
      }
      &:first-of-type {
        margin-top: 5px;
      }
      &:last-of-type {
        margin-bottom: 5px;
      }
    }
  }

}

