/*
 =========================================================
 * CUSTOM CLIENT PAGE DESIGN
 =========================================================
 */
 
 app-client {
  .client-aside {
    padding: 10px;
  
    .ca-header {
      color: $text-color; //$dark-gray;
      line-height: 2.5;
      font-weight: normal;
      margin-bottom: 10px;
      border-bottom: 1px solid $dark-gray;
    }
    .ca-property-list {
      height: calc(100% - 50px); // - first h4.ca-header
      overflow-y: auto;
      padding: 2px 6px 0 0;
  
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $custom-scrollbar-color;
        border-radius: 2px;
      }
    }
    .ca-property-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      
      border-left-width: 5px;
      border-left-style: solid;
      border-color: $light-gray;
      margin-bottom: 15px;
      box-shadow: $shadowMain;
      background: $content-bg-color;
  
      .property-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 66px;
        height: 66px;
        margin-right: 10px;
        cursor: pointer;
      }
      .property-name {
        font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
        color: $text-color; //$dark-gray;
        padding-top: 5px;
      }
      .rating-stars span {
        font-size: 26px;
      }
    }
  }

}